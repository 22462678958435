import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryId: null,
  subCategoryId: null,
  subCategoryTitle: null,
  categoryTitle: null,
  templateId: null,
  templateVersion: 1,
  templateData: {},
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setCategoryId: (state, action) => {
      state.categoryId = action.payload.id;
      state.categoryTitle = action.payload.title;
    },
    setAllData: (state, action) => {
      state.categoryId = action.payload.categoryId;
      state.categoryTitle = action.payload.categoryTitle;
      state.templateId = action.payload.templateId;
      state.subCategoryId = action.payload.subCategoryId;
      state.subCategoryTitle = action.payload.subCategoryTitle;
    },
    setTemplateData: (state, action) => {
      state.templateData = { ...state.templateData, ...action.payload };
    },
    setSubCategoryId: (state, action) => {
      state.subCategoryId = action.payload.id;
      state.subCategoryTitle = action.payload.title;
    },
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setTemplateVersion: (state, action) => {
      state.templateVersion = action.payload;
    },
    resetProfileSelection: (state, action) => {
      return initialState;
    },
    updateTemplateData: (state, action) => {
      state.templateData[action.payload.key] = action.payload.value;
    },
    addTemplateData: (state, action) => {
      state.templateData[action.payload.key] = [
        ...state.templateData[action.payload.key],
        {},
      ];
    },
    deleteTemplateData: (state, action) => {
      const updatedData = [...state.templateData[action.payload.key]];

      // Remove the item at the specified index
      updatedData.splice(action.payload.index, 1);

      // Update the state with the modified data
      state.templateData[action.payload.key] = updatedData;
    },
    updateTemplateObjectData: (state, action) => {
      if (state.templateData[action.payload.key]) {
        state.templateData[action.payload.key][action.payload.index] =
          action.payload.value;
      } else {
        state.templateData[action.payload.key] = [action.payload.value];
      }
    },
    updateTemplateObjectKeyData: (state, action) => {
      console.log(action.payload);
      if (action.payload.index || action.payload.index === 0) {
        state.templateData[action.payload.key][action.payload.index][
          action.payload.innerKey
        ] = action.payload.value;
      } else {
        state.templateData[action.payload.key][action.payload.innerKey] =
          action.payload.value;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
