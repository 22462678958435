import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Platform,
  Picker,
  CheckBox,
  Pressable,
  Image,
} from "react-native";
import Input from "./Input";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { profileActions } from "../store/slices/profile";
import { connect } from "react-redux";
import ImagePlaceHolder from "./ImagePlaceHolder";
import FamilyTypePlaceholder from "./FamilyTypePlaceholder";
import { useEffect } from "react";
import "antd/dist/antd.css";
import "../ant.css";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import AudioPlaceHolder from "./AudioPlaceHolder";
import CustomText from "./CustomText";

const Accordion = ({
  data,
  populateTemplateData,
  setData,
  setKeyData,
  setObjectData,
  addObject,
  deleteObject,
  templateVersion,
}) => {
  console.log(data, "formdata");
  const navigation = useNavigation();
  const [expanded, setExpanded] = useState({});

  const getOrCreateData = (itemKey, fieldKey, notReturn) => {
    const dataKey = templateVersion === 1 ? fieldKey : `${itemKey}_${fieldKey}`;

    if (!populateTemplateData[dataKey]) {
      setObjectData({
        key: dataKey,
        value: {},
      }); // Create an empty array if it doesn't exist
    }

    if (!notReturn) return populateTemplateData[dataKey];
  };

  const getSortArray = (item, groomSide) => {
    console.log(item, item.title);
    if (item.title === "Family Info" || item.title === "Basic") {
      const data = [...item.fields].sort((a, b) => {
        // Check if 'isGroom' key exists in both objects
        const aHasGroom = "isGroom" in a;
        const bHasGroom = "isGroom" in b;

        // Handle cases where 'isGroom' key is missing in either object
        if (!aHasGroom || !bHasGroom) {
          if (!aHasGroom && !bHasGroom) {
            return 0; // If both objects don't have 'isGroom' key, maintain original order
          } else if (!aHasGroom) {
            return -1; // If 'isGroom' is missing in 'a', prioritize 'a' first
          } else {
            return 1; // If 'isGroom' is missing in 'b', prioritize 'b' first
          }
        }

        // Both objects have 'isGroom' key, sort based on 'isGroom' and 'groomSide'
        if (a.isGroom !== b.isGroom) {
          if (groomSide) {
            return a.isGroom ? -1 : 1; // If groomSide is true, prioritize isGroom items first
          } else {
            return a.isGroom ? 1 : -1; // If groomSide is false, prioritize non-isGroom items first
          }
        }

        return 0; // If isGroom values are the same, maintain the original order
      });
      return [...data].map((i) => {
        let newItem = { ...i };

        // If the object is not for the groom, replace 'Groom' with 'Bride' in the title
        if (!newItem.isGroom) {
          newItem.title = newItem.title.replace("Groom", "Bride");
        }

        return newItem;
      });
    } else {
      return item.fields;
    }
  };

  useEffect(() => {
    setExpanded({ 0: true });
  }, []);

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      [index]: true,
    }));
  };

  const openModal = (screen, key) => {
    return new Promise((resolve) => {
      navigation.navigate(screen, {
        key: key,
        onModalClose: (value) => {
          console.log("Received value from modal:", value);
          resolve(value); // Resolve the promise with the received value
        },
      });
    });
  };
  useEffect(() => {
    setData({
      key: "family_side",
      value: "groom",
    });
    const fetchFamilyType = async () => {
      if (templateVersion !== 1 && templateVersion !== 3) {
        const returnValue = await openModal("ChooseFamilyType");
        setData({
          key: "family_side",
          value: returnValue ?? "groom",
        });
      }
    };
    fetchFamilyType();
  }, []);

  const renderItem = ({ item, index }) => {
    const isExpanded = expanded[index];
    return (
      <View style={styles.itemContainer}>
        <TouchableOpacity onPress={() => toggleExpand(index)}>
          <CustomText style={styles.title}>
            <View
              style={{
                marginRight: 12,
                width: 28,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 14,
                backgroundColor: "#a2a2a2",
                aspectRatio: 1 / 1,
              }}
            >
              <CustomText style={[styles.title, { color: "white" }]}>
                {index + 1}
              </CustomText>
            </View>
            {item.title}
          </CustomText>
        </TouchableOpacity>
        {isExpanded && (
          <View style={styles.expandedContent}>
            {getSortArray(
              item,
              // populateTemplateData["family_side"] === "groom" ||
              populateTemplateData[
                item.title === "Basic" ? "basic_family_side" : "family_side"
              ] === "groom" ||
                populateTemplateData[
                  item.title === "Basic" ? "basic_family_side" : "family_side"
                ] === ""
            ).map((field) => {
              return (
                <View style={{ rowGap: 4, marginBottom: 8 }}>
                  {field.type !== "EVENT" &&
                    field.type !== "DROPDOWN_CUSTOM_SIDE" && (
                      <CustomText style={styles.fieldTitle}>
                        {field.title}
                      </CustomText>
                    )}
                  {!!field.detail && (
                    <CustomText style={styles.fieldDetail}>
                      {field.detail}
                    </CustomText>
                  )}
                  {field.type === "TEXT_BOX" && (
                    <Input
                      // label="OTP"
                      placeholder={field.placeholder}
                      multiline={field.max_lines > 1}
                      boxHeight={
                        field.max_lines > 1 ? 21 * field.max_lines : 42
                      }
                      styleProp={{
                        borderColor: "#f09aab",
                        borderWidth: 1,
                        borderRadius: 4,
                      }}
                      value={
                        populateTemplateData[
                          templateVersion === 1
                            ? field.key
                            : `${item.key}_${field.key}`
                        ]
                      }
                      onChangeText={(e) => {
                        setData({
                          key:
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`,
                          value: e,
                        });
                      }}
                    />
                  )}
                  {field.type === "DATE_PICKER" && (
                    <DatePicker
                      defaultValue={
                        populateTemplateData[
                          templateVersion === 1
                            ? field.key
                            : `${item.key}_${field.key}`
                        ]
                          ? moment(
                              populateTemplateData[
                                templateVersion === 1
                                  ? field.key
                                  : `${item.key}_${field.key}`
                              ],
                              "DD-MM-YYYY"
                            )
                          : ""
                      }
                      onChange={(e) =>
                        setData({
                          key:
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`,
                          value: e.format("DD-MM-YYYY"),
                        })
                      }
                      disabledDate={(current) => {
                        // If the current date is before today, disable it
                        return current && current < moment().startOf("day");
                      }}
                      format="DD-MM-YYYY"
                    />
                  )}
                  {field.type === "DROPDOWN" && (
                    <Picker
                      style={{
                        borderColor: "#f09aab",
                        borderWidth: 1,
                        borderRadius: 4,
                        padding: 4,
                      }}
                      selectedValue={
                        populateTemplateData[
                          templateVersion === 1
                            ? field.key
                            : `${item.key}_${field.key}`
                        ]
                      }
                      onValueChange={(e) =>
                        setData({
                          key:
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`,
                          value: e,
                        })
                      }
                    >
                      {field.choices_list?.map((choice) => (
                        <Picker.Item label={choice.title} value={choice.key} />
                      ))}
                    </Picker>
                  )}

                  {field.type === "DROPDOWN_CUSTOM_SIDE" && (
                    <FamilyTypePlaceholder
                      key={index}
                      value={populateTemplateData[field.key]}
                      onPress={async () => {
                        const returnValue = await openModal("ChooseFamilyType");
                        setData({
                          value: returnValue,
                          key: field.key,
                        });

                        console.log(returnValue);
                      }}
                    />
                  )}
                  {field.type === "TIME_PICKER" && (
                    <TimePicker
                      defaultValue={
                        populateTemplateData[
                          templateVersion === 1
                            ? field.key
                            : `${item.key}_${field.key}`
                        ]
                          ? moment(
                              populateTemplateData[
                                templateVersion === 1
                                  ? field.key
                                  : `${item.key}_${field.key}`
                              ],
                              "hh:mm A"
                            )
                          : ""
                      }
                      format="hh:mm A"
                      onChange={(e) =>
                        setData({
                          key:
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`,
                          value: e.format("hh:mm A"),
                        })
                      }
                    />
                  )}
                  {field.type === "MEDIA" && (
                    <View
                      style={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      {Array.from({ length: field.max }, (_, index) => {
                        return (
                          <View style={{ width: "30%" }}>
                            <ImagePlaceHolder
                              key={index}
                              uri={
                                !!populateTemplateData[
                                  templateVersion === 1
                                    ? field.key
                                    : `${item.key}_${field.key}`
                                ] &&
                                populateTemplateData[
                                  templateVersion === 1
                                    ? field.key
                                    : `${item.key}_${field.key}`
                                ][index]?.url
                              }
                              onPress={async () => {
                                const returnValue = await openModal(
                                  "ChooseFile",
                                  "IMAGES"
                                );
                                setObjectData({
                                  value: returnValue,
                                  key:
                                    templateVersion === 1
                                      ? field.key
                                      : `${item.key}_${field.key}`,
                                  index,
                                });

                                console.log(returnValue);
                              }}
                            />
                          </View>
                        );
                      })}
                    </View>
                  )}
                  {field.type === "AUDIO" && (
                    <View
                      style={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <AudioPlaceHolder
                        key={index}
                        uri={
                          !!populateTemplateData[
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`
                          ] &&
                          populateTemplateData[
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`
                          ]?.url
                        }
                        title={
                          !!populateTemplateData[
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`
                          ] &&
                          populateTemplateData[
                            templateVersion === 1
                              ? field.key
                              : `${item.key}_${field.key}`
                          ]?.name
                        }
                        onPress={async () => {
                          const returnValue = await openModal(
                            "ChooseFile",
                            "AUDIOS"
                          );
                          setData({
                            value: returnValue,
                            key:
                              templateVersion === 1
                                ? field.key
                                : `${item.key}_${field.key}`,
                          });
                        }}
                        deleteFunction={() =>
                          setData({
                            value: {},
                            key:
                              templateVersion === 1
                                ? field.key
                                : `${item.key}_${field.key}`,
                          })
                        }
                      />
                    </View>
                  )}
                  {field.type === "EVENT" && (
                    <>
                      {getOrCreateData(item.key, field.key).map(
                        (value, index) => {
                          return (
                            <>
                              {templateVersion !== 2 && (
                                <View
                                  style={{
                                    backgroundColor: "#407ce4",
                                    padding: 4,
                                    borderRadius: 4,
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                >
                                  <CustomText
                                    style={[
                                      styles.fieldTitle,
                                      { color: "white" },
                                    ]}
                                  >
                                    {field.title} {index + 1}{" "}
                                  </CustomText>
                                  <TouchableOpacity
                                    onPress={() => {
                                      deleteObject({
                                        key:
                                          templateVersion === 1
                                            ? field.key
                                            : `${item.key}_${field.key}`,
                                        index: index,
                                      });
                                    }}
                                  >
                                    <MaterialIcons
                                      name="delete"
                                      size={16}
                                      color="white"
                                    />
                                  </TouchableOpacity>
                                </View>
                              )}
                              {field.fields.map((innerField) => (
                                <>
                                  {innerField.type !== "SUB_INPUT" &&
                                    innerField.type !== "SUB_SELECTION" && (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          columnGap: 6,
                                        }}
                                      >
                                        {innerField.type === "CHECKBOX" && (
                                          <CheckBox
                                            value={
                                              value[innerField.key] ===
                                              "enabled"
                                                ? true
                                                : false
                                            }
                                            onValueChange={() => {
                                              setKeyData({
                                                key:
                                                  templateVersion === 1
                                                    ? field.key
                                                    : `${item.key}_${field.key}`,
                                                value:
                                                  value[innerField.key] !==
                                                  "enabled"
                                                    ? "enabled"
                                                    : "disabled",
                                                index,
                                                innerKey: innerField.key,
                                              });
                                            }}
                                          />
                                        )}
                                        <CustomText style={styles.fieldTitle}>
                                          {innerField.title}
                                        </CustomText>
                                      </View>
                                    )}
                                  {innerField.type === "DATE_PICKER" && (
                                    <DatePicker
                                      defaultValue={
                                        value[innerField.key]
                                          ? moment(
                                              value[innerField.key],
                                              "DD-MM-YYYY"
                                            )
                                          : ""
                                      }
                                      disabledDate={(current) => {
                                        // If the current date is before today, disable it
                                        return (
                                          current &&
                                          current < moment().startOf("day")
                                        );
                                      }}
                                      onChange={(e) =>
                                        setKeyData({
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          value: e.format("DD-MM-YYYY"),
                                          index,
                                          innerKey: innerField.key,
                                        })
                                      }
                                      format="DD-MM-YYYY"
                                    />
                                  )}
                                  {innerField.type === "TIME_PICKER" && (
                                    <TimePicker
                                      defaultValue={
                                        value[innerField.key]
                                          ? moment(
                                              value[innerField.key],
                                              "hh:mm A"
                                            )
                                          : ""
                                      }
                                      format="hh:mm A"
                                      onChange={(e) =>
                                        setKeyData({
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          value: e.format("hh:mm A"),
                                          index,
                                          innerKey: innerField.key,
                                        })
                                      }
                                    />
                                  )}
                                  {innerField.type === "TEXT_BOX" && (
                                    <Input
                                      placeholder={innerField.placeholder}
                                      multiline={innerField.max_lines > 1}
                                      boxHeight={
                                        innerField.max_lines > 1
                                          ? 21 * innerField.max_lines
                                          : 42
                                      }
                                      value={value[innerField.key]}
                                      styleProp={{
                                        borderColor: "#f09aab",
                                        borderWidth: 1,
                                        borderRadius: 4,
                                      }}
                                      onChangeText={(e) => {
                                        setKeyData({
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          value: e,
                                          index,
                                          innerKey: innerField.key,
                                        });
                                      }}
                                    />
                                  )}
                                  {innerField.type === "SUB_INPUT" && (
                                    <Pressable
                                      onPress={async () => {
                                        const returnValue = await openModal(
                                          "ChooseSubInput",
                                          innerField.selection_type
                                        );
                                        setObjectData({
                                          value: {
                                            [innerField.key]: returnValue.type,
                                            event_clip: returnValue.icon,
                                            [`${
                                              innerField.key.split("_")[0]
                                            }_value`]: returnValue.value,
                                          },
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          index,
                                        });
                                      }}
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        columnGap: 20,
                                        paddingHorizontal: 12,
                                        paddingVertical: 4,
                                      }}
                                    >
                                      {value?.event_clip?.url && (
                                        <Image
                                          style={{ width: 18, height: 18 }}
                                          source={{
                                            uri: value.event_clip?.url,
                                          }}
                                        />
                                      )}
                                      <CustomText style={styles.fieldTitle}>
                                        {
                                          value[
                                            `${
                                              innerField.key.split("_")[0]
                                            }_value`
                                          ]
                                        }
                                      </CustomText>
                                    </Pressable>
                                  )}
                                  {innerField.type === "SUB_SELECTION" && (
                                    <Pressable
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        columnGap: 20,
                                        paddingHorizontal: 12,
                                        paddingVertical: 4,
                                      }}
                                      onPress={async () => {
                                        const returnValue = await openModal(
                                          "ChooseSubSelection",
                                          innerField.selection_type
                                        );
                                        setObjectData({
                                          value: {
                                            [innerField.key]: returnValue.type,
                                            event_clip: returnValue.icon,
                                            [`${
                                              innerField.key.split("_")[0]
                                            }_value`]: returnValue.value,
                                          },
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          index,
                                        });
                                      }}
                                    >
                                      <CustomText style={styles.fieldTitle}>
                                        {value[innerField.key]} -{" "}
                                        {
                                          value[
                                            `${
                                              innerField.key.split("_")[0]
                                            }_value`
                                          ]
                                        }
                                      </CustomText>
                                      <MaterialIcons
                                        name="edit"
                                        size={24}
                                        color="black"
                                      />
                                    </Pressable>
                                  )}
                                </>
                              ))}
                            </>
                          );
                        }
                      )}
                      {templateVersion !== 2 && (
                        <TouchableOpacity
                          style={{ padding: 16, alignItems: "center" }}
                          onPress={() => {
                            addObject({
                              key:
                                templateVersion === 1
                                  ? field.key
                                  : `${item.key}_${field.key}`,
                              innerKey: field.key,
                            });
                          }}
                        >
                          <CustomText
                            style={{ color: "#407ce4", fontWeight: "bold" }}
                          >
                            ADD
                          </CustomText>
                        </TouchableOpacity>
                      )}
                    </>
                  )}
                  {field.type === "PROFILE" && (
                    <>
                      {getOrCreateData(item.key, field.key, true)}
                      <>
                        {field.isProfilePic && (
                          <View style={{ width: "30%" }}>
                            <ImagePlaceHolder
                              key={index}
                              uri={
                                !!populateTemplateData[
                                  templateVersion === 1
                                    ? field.key
                                    : `${item.key}_${field.key}`
                                ] &&
                                populateTemplateData[
                                  templateVersion === 1
                                    ? field.key
                                    : `${item.key}_${field.key}`
                                ].image?.url
                              }
                              onPress={async () => {
                                const returnValue = await openModal(
                                  "ChooseFile",
                                  "IMAGES"
                                );
                                setKeyData({
                                  value: returnValue,
                                  key:
                                    templateVersion === 1
                                      ? field.key
                                      : `${item.key}_${field.key}`,
                                  innerKey: "image",
                                });

                                console.log(returnValue);
                              }}
                            />
                          </View>
                        )}
                        <Input
                          placeholder={field.placeholder}
                          value={
                            populateTemplateData[
                              templateVersion === 1
                                ? field.key
                                : `${item.key}_${field.key}`
                            ].title
                          }
                          styleProp={{
                            borderColor: "#f09aab",
                            borderWidth: 1,
                            borderRadius: 4,
                          }}
                          onChangeText={(e) => {
                            setKeyData({
                              key:
                                templateVersion === 1
                                  ? field.key
                                  : `${item.key}_${field.key}`,
                              value: e,
                              innerKey: "title",
                            });
                          }}
                        />
                        {field.isDetail && (
                          <>
                            <CustomText style={styles.fieldTitle}>
                              {field.detailField.title}
                            </CustomText>
                            <Input
                              placeholder={field.placeholder}
                              onFocus={
                                field.detailField.suggestion
                                  ? async () => {
                                      const returnValue = await openModal(
                                        "ChooseFile",
                                        "BIO_TEXT"
                                      );
                                      setKeyData({
                                        value: returnValue.text,
                                        key:
                                          templateVersion === 1
                                            ? field.key
                                            : `${item.key}_${field.key}`,
                                        innerKey: "detail",
                                      });

                                      console.log(returnValue);
                                    }
                                  : () => {}
                              }
                              value={
                                populateTemplateData[
                                  templateVersion === 1
                                    ? field.key
                                    : `${item.key}_${field.key}`
                                ].detail
                              }
                              styleProp={{
                                borderColor: "#f09aab",
                                borderWidth: 1,
                                borderRadius: 4,
                              }}
                              onChangeText={(e) => {
                                setKeyData({
                                  key:
                                    templateVersion === 1
                                      ? field.key
                                      : `${item.key}_${field.key}`,
                                  value: e,
                                  innerKey: "detail",
                                });
                              }}
                            />
                          </>
                        )}
                        {/* {field.fields.map((innerField) => (

                                <>
                                  {innerField.type !== "SUB_INPUT" &&
                                    innerField.type !== "SUB_SELECTION" && (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          columnGap: 6,
                                        }}
                                      >
                                        {innerField.type === "CHECKBOX" && (
                                          <CheckBox
                                            value={
                                              value[innerField.key] ===
                                              "enabled"
                                                ? true
                                                : false
                                            }
                                            onValueChange={() => {
                                              setKeyData({
                                                key:
                                                  templateVersion === 1
                                                    ? field.key
                                                    : `${item.key}_${field.key}`,
                                                value:
                                                  value[innerField.key] !==
                                                  "enabled"
                                                    ? "enabled"
                                                    : "disabled",
                                                index,
                                                innerKey: innerField.key,
                                              });
                                            }}
                                          />
                                        )}
                                        <CustomText style={styles.fieldTitle}>
                                          {innerField.title}
                                        </CustomText>
                                      </View>
                                    )}
                                  {innerField.type === "DATE_PICKER" && (
                                    <DatePicker
                                      defaultValue={
                                        value[innerField.key]
                                          ? moment(
                                              value[innerField.key],
                                              "DD-MM-YYYY"
                                            )
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setKeyData({
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          value: e.format("DD-MM-YYYY"),
                                          index,
                                          innerKey: innerField.key,
                                        })
                                      }
                                      format="DD-MM-YYYY"
                                    />
                                  )}
                                  {innerField.type === "TIME_PICKER" && (
                                    <TimePicker
                                      defaultValue={
                                        value[innerField.key]
                                          ? moment(
                                              value[innerField.key],
                                              "hh:mm A"
                                            )
                                          : ""
                                      }
                                      format="hh:mm A"
                                      onChange={(e) =>
                                        setKeyData({
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          value: e.format("hh:mm A"),
                                          index,
                                          innerKey: innerField.key,
                                        })
                                      }
                                    />
                                  )}
                                  
                                  {innerField.type === "SUB_INPUT" && (
                                    <Pressable
                                      onPress={async () => {
                                        const returnValue = await openModal(
                                          "ChooseSubInput",
                                          innerField.selection_type
                                        );
                                        setObjectData({
                                          value: {
                                            [innerField.key]: returnValue.type,
                                            event_clip: returnValue.icon,
                                            [`${
                                              innerField.key.split("_")[0]
                                            }_value`]: returnValue.value,
                                          },
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          index,
                                        });
                                      }}
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        columnGap: 20,
                                        paddingHorizontal: 12,
                                        paddingVertical: 4,
                                      }}
                                    >
                                      {value?.event_clip?.url && (
                                        <Image
                                          style={{ width: 18, height: 18 }}
                                          source={{
                                            uri: value.event_clip?.url,
                                          }}
                                        />
                                      )}
                                      <CustomText style={styles.fieldTitle}>
                                        {
                                          value[
                                            `${
                                              innerField.key.split("_")[0]
                                            }_value`
                                          ]
                                        }
                                      </CustomText>
                                    </Pressable>
                                  )}
                                  {innerField.type === "SUB_SELECTION" && (
                                    <Pressable
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        columnGap: 20,
                                        paddingHorizontal: 12,
                                        paddingVertical: 4,
                                      }}
                                      onPress={async () => {
                                        const returnValue = await openModal(
                                          "ChooseSubSelection",
                                          innerField.selection_type
                                        );
                                        setObjectData({
                                          value: {
                                            [innerField.key]: returnValue.type,
                                            event_clip: returnValue.icon,
                                            [`${
                                              innerField.key.split("_")[0]
                                            }_value`]: returnValue.value,
                                          },
                                          key:
                                            templateVersion === 1
                                              ? field.key
                                              : `${item.key}_${field.key}`,
                                          index,
                                        });
                                      }}
                                    >
                                      <CustomText style={styles.fieldTitle}>
                                        {value[innerField.key]} -{" "}
                                        {
                                          value[
                                            `${
                                              innerField.key.split("_")[0]
                                            }_value`
                                          ]
                                        }
                                      </CustomText>
                                      <MaterialIcons
                                        name="edit"
                                        size={24}
                                        color="black"
                                      />
                                    </Pressable>
                                  )}
                                </>
                              ))} */}
                      </>

                      {/* {templateVersion !== 2 && (
                        <TouchableOpacity
                          style={{ padding: 16, alignItems: "center" }}
                          onPress={() => {
                            addObject({
                              key:
                                templateVersion === 1
                                  ? field.key
                                  : `${item.key}_${field.key}`,
                              innerKey: field.key,
                            });
                          }}
                        >
                          <CustomText
                            style={{ color: "#407ce4", fontWeight: "bold" }}
                          >
                            ADD
                          </CustomText>
                        </TouchableOpacity>
                      )} */}
                    </>
                  )}
                </View>
              );
            })}
            <TouchableOpacity
              style={{ padding: 16 }}
              onPress={() =>
                toggleExpand(index + 1 >= data.length ? 0 : index + 1)
              }
            >
              <CustomText style={{ color: "#407ce4", fontWeight: "bold" }}>
                NEXT
              </CustomText>
            </TouchableOpacity>
          </View>
        )}
        {/* {index < data.length - 1 && <View style={styles.line} />} */}
      </View>
    );
  };

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={(item, index) => index.toString()}
    />
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  title: {
    fontSize: 18,
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  fieldDetail: {
    fontSize: 12,
    color: "fafafa",
  },
  expandedContent: {
    marginTop: 6,
    padding: 16,
  },
  line: {
    height: 1,
    backgroundColor: "gray",
  },
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setData: (data) => dispatch(profileActions.updateTemplateData(data)),
  setKeyData: (data) =>
    dispatch(profileActions.updateTemplateObjectKeyData(data)),
  setObjectData: (data) =>
    dispatch(profileActions.updateTemplateObjectData(data)),
  addObject: (data) => dispatch(profileActions.addTemplateData(data)),
  deleteObject: (data) => dispatch(profileActions.deleteTemplateData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);
