import React, { useState } from "react";
import { View, ScrollView, Image, Dimensions, Platform } from "react-native";

const ImageSlider = ({ data }) => {
  const { width: windowWidth } = Dimensions.get("window");
  const width = windowWidth > 480 ? 480 : windowWidth;

  return (
    <View style={{ height: 300, width: width }}>
      <ScrollView
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={200}
      >
        {data?.map((image, index) => (
          <Image
            key={index}
            source={{ uri: image.image_url }}
            style={[
              {
                width: width - 2,
                height: 300,
                marginRight: 2,
              },
            ]}
            resizeMode="contain"
          />
        ))}
      </ScrollView>
    </View>
  );
};

export default ImageSlider;
