import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  Platform,
} from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import CustomText from "./CustomText";

const ProfileList = ({ data }) => {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <CustomText style={styles.heading}>Categories</CustomText>
      <FlatList
        data={data}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={(itemData) => {
          console.log(itemData);
          return (
            <Pressable
              style={styles.categoryContainer}
              onPress={() => {
                navigation.navigate("ProfileSubCategory", {
                  id: itemData.item.category_id,
                });
              }}
            >
              <Image
                source={{ uri: itemData.item.image_url }}
                style={[
                  {
                    resizeMode: "contain",
                    width: 72,
                    height: 72,
                    borderRadius: 36,
                    borderColor: "#f47199",
                    backgroundColor: "#7c94b6",
                    borderWidth: 4,
                  },
                  //   tailwind`rounded-xl w-10/12`,
                ]}
              />
              <CustomText style={{ fontWeight: "bold", textAlign: "center" }}>
                {itemData.item.name}
              </CustomText>
            </Pressable>
          );
        }}
        keyExtractor={(item) => {
          return item.profile_category_id;
        }}
        alwaysBounceVertical={false}
      />
    </View>
  );
};

export default ProfileList;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    rowGap: 12,
  },
  categoryContainer: {
    width: 108,
    rowGap: 12,
    alignItems: "center",
  },
  heading: {
    color: "#ooo",
    fontSize: 16,
    fontWeight: "bold",
  },
});
