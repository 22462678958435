import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { connect } from "react-redux";
import { Entypo, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import ListView from "../components/ListView";
import CustomText from "../components/CustomText";

const ContactUsScreen = ({ config }) => {
  const data = [
    {
      subTitle: "Contact name",
      title: config["app.config.contact.name"],
      Icon: <MaterialIcons name="verified-user" size={24} color="#b6e2d3" />,
    },
    {
      subTitle: "Contact Mobile",
      title: config["app.config.contact.mobile"],
      Icon: <Entypo name="mobile" size={24} color="#b6e2d3" />,
    },
    {
      subTitle: "Contact Email",
      title: config["app.config.contact.email"],
      Icon: <FontAwesome name="envelope" size={24} color="#b6e2d3" />,
    },
    {
      subTitle: "Contact Address",
      title: config["app.config.contact.address"],
      Icon: <Entypo name="home" size={24} color="#b6e2d3" />,
    },
  ];

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Contact Details</CustomText>
      </View>
      <View style={styles.container}>
        <ListView data={data} uniqueKey="title" hideSearchBar />
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  config: state?.cms?.config,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
});
