export function findUniqueTags(jsonData) {
  let uniqueTags = new Set();

  uniqueTags.add("");
  jsonData.forEach((item) => {
    if (item.tags && Array.isArray(item.tags)) {
      item.tags.forEach((tag) => {
        if (tag.tags.trim().length > 0) {
          const individualTags = tag.tags.split(",");
          individualTags.forEach((t) => uniqueTags.add(t.trim()));
        }
      });
    }
  });
  let uniqueTagsArray = Array.from(uniqueTags).map((tag) => ({
    key: tag.trim(),
    value: tag.trim(),
  }));

  uniqueTagsArray = uniqueTagsArray.map((tagObj) => {
    if (tagObj.value === "") {
      return { key: "All Invites", value: tagObj.value };
    }
    return tagObj;
  });

  return uniqueTagsArray;
}

export function findItemsByTag(jsonData, tagToFind) {
  const itemsWithSpecificTag = jsonData.filter((item) => {
    if (item.tags && Array.isArray(item.tags)) {
      return item.tags.some((tag) => tag.tags.includes(tagToFind));
    }
    return false;
  });

  return itemsWithSpecificTag;
}
