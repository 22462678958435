import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  Platform,
} from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import CustomText from "./CustomText";

const CategoryList = ({ data, title }) => {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <View style={styles.headingContainer}>
        <CustomText style={styles.heading}>{title}</CustomText>
        <Pressable onPress={() => navigation.navigate("ProfileCards")}>
          <CustomText style={styles.heading}>View more</CustomText>
        </Pressable>
      </View>
      <FlatList
        data={data}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={(itemData) => {
          return (
            <Pressable
              style={[
                styles.categoryContainer,
                {
                  ...Platform.select({
                    ios: {
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                      shadowOffset: { width: 0, height: 5 },
                      shadowOpacity: 1,
                      shadowRadius: 5,
                    },
                    // For Android
                    android: {
                      elevation: 15,
                    },
                  }),
                },
              ]}
              onPress={() => {
                navigation.navigate("Product", {
                  id: itemData.item.product_id,
                });
              }}
            >
              <Image
                source={{ uri: itemData.item.image_url }}
                style={[
                  {
                    backgroundColor: "#7c94b6",
                    resizeMode: "cover",
                    width: 92,
                    height: 147,
                    borderRadius: 24,
                  },
                ]}
              />
            </Pressable>
          );
        }}
        keyExtractor={(item) => {
          return item.profile_id;
        }}
        alwaysBounceVertical={false}
      />
    </View>
  );
};

export default CategoryList;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    rowGap: 12,
  },
  categoryContainer: {
    margin: 12,
    rowGap: 12,
    alignItems: "center",
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
});
