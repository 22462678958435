import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  FlatList,
  ActivityIndicator,
  Image,
  TextInput,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReviews, getTemplateMesasgeData } from "../store/actions/cms";
import BackdropModal from "../components/BackdropModal";
import ImagePicker from "../components/ImagePicker";
import AudioPicker from "../components/AudioPicker";
import CustomText from "../components/CustomText";

const ChooseFileScreen = ({
  templateMessageData,
  route,
  token,
  loading,
  getData,
  navigation,
  templateId,
}) => {
  const { key, onModalClose } = route.params;
  const [value, setValue] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!templateId) {
      setTimeout(() => navigation.navigate("Dashboard"), 100);
    } else {
      if (!templateMessageData) getData(token);
    }
  }, [templateMessageData]);

  const handleAdd = (data) => {
    onModalClose(data);
    navigation.goBack();
  };

  const handleSelection = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      {key === "IMAGES" && <ImagePicker onModalClose={handleAdd} />}
      {key === "AUDIOS" && <AudioPicker onModalClose={handleAdd} />}
      {key === "BIO_TEXT" && (
        <Pressable onPress={handleSelection} style={styles.textContainer}>
          <CustomText style={styles.heading}>Click here to Add new</CustomText>
        </Pressable>
      )}
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>
          {key.replace("_", " ")}
        </CustomText>
      </View>
      <View style={styles.container}>
        <View style={{ flex: 1 }}>
          <FlatList
            data={templateMessageData?.filter(
              (item) => item.template_type === key
            )}
            numColumns={key === "IMAGES" ? 2 : 1}
            showsVerticalScrollIndicator={false}
            renderItem={(itemData) => {
              return (
                <Pressable
                  style={styles.categoryContainer}
                  onPress={() => handleAdd(itemData.item.data)}
                >
                  {key === "IMAGES" ? (
                    <Image
                      source={{ uri: itemData?.item?.data?.url }}
                      style={{
                        width: "100%",
                        aspectRatio: 1 / 1,
                        resizeMode: "contain",
                      }}
                    />
                  ) : (
                    <CustomText>
                      {key === "AUDIOS"
                        ? itemData?.item?.data?.name
                        : itemData?.item?.data?.text}
                    </CustomText>
                  )}
                </Pressable>
              );
            }}
            keyExtractor={(item) => {
              return item.data.title;
            }}
            alwaysBounceVertical={false}
          />
        </View>
      </View>
      <BackdropModal
        visible={modalVisible}
        onClose={handleModalClose}
        Content={
          <>
            <CustomText style={styles.modalHeading}>Value</CustomText>
            <TextInput
              placeholder="Enter something..."
              onChangeText={setValue}
              value={value}
              style={styles.input}
            />
            <View style={styles.lowerContainer}>
              <View
                style={{
                  flex: 1,
                  padding: 6,
                  columnGap: 5,
                  flexDirection: "row",
                }}
              >
                <Pressable style={styles.button} onPress={handleModalClose}>
                  <CustomText style={styles.buttonText}>Cancel</CustomText>
                </Pressable>
                <Pressable
                  style={styles.button}
                  onPress={() => handleAdd({ text: value })}
                >
                  <CustomText style={styles.buttonText}>Add</CustomText>
                </Pressable>
              </View>
            </View>
          </>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  templateMessageData: state?.cms?.formMessageData,
  token: state?.user?.token,
  templateId: state?.profile?.templateId,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getTemplateMesasgeData(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseFileScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    marginTop: 16,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    height: 60,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    columnGap: 16,
  },
  heading: {
    fontSize: 18,
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  primaryHeading: {
    fontWeight: "bold",
    fontSize: 16,
  },
  categoryContainer: {
    flex: 1,
    margin: 16,
    padding: 4,
    borderColor: "black",
    borderWidth: 2,
    borderRadius: 2,
    rowGap: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeading: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  input: {
    height: 40,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  lowerContainer: {
    height: 48,
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#3878e2",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
