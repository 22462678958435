import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { AntDesign } from "@expo/vector-icons";

const FamilyPlaceHolder = ({ value, onPress }) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <Image
        style={{ width: "40%", aspectRatio: 1.5, borderRadius: 10 }}
        source={{
          uri:
            value === "groom"
              ? "https://ewr1.vultrobjects.com/cdn-sei/original/21ff98eb-6ae6-4ca7-8fa6-495ab8e668aa.png"
              : "https://ewr1.vultrobjects.com/cdn-sei/original/67fa8378-1589-4c3d-b394-007ed5c69f12.png",
        }}
      />
    </Pressable>
  );
};

export default FamilyPlaceHolder;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    borderRadius: 8,
    margin: 6,
    minWidth: 100,
  },
  heading: {
    fontSize: 18,
  },
});
