import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import WebView from "../components/WebView";
import ListView from "../components/ListView";
import { profileActions } from "../store/slices/profile";
import { getDashboard, getProducts } from "../store/actions/cms";
import { findUniqueTags } from "../utils/mapper";
import Footer from "../components/Footer";
import { notificationActions } from "../store/slices/notification";
import CreateCardsList from "../components/CreateCardsList";
import CustomText from "../components/CustomText";

const ProfileSubCategoryScreen = ({
  products,
  route,
  loading,
  getProductsData,
  navigation,
  getData,
  dashboardData,
}) => {
  const dispatch = useDispatch();
  const { id } = route.params;

  const [category, setCategory] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (!dashboardData) {
        dispatch(notificationActions.toggleSpinnerLoading(true));
        await Promise.all([getData(1), getProductsData(1, id)]);
        dispatch(notificationActions.toggleSpinnerLoading(false));
      } else {
        await getProductsData(0, id);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <ScrollView
        style={[styles.container]}
        showsVerticalScrollIndicator={false}
      >
        <ScrollView
          style={{ flexDirection: "row", padding: 16 }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {products &&
            products?.length > 0 &&
            findUniqueTags(products) &&
            findUniqueTags(products).map((cat) => (
              <>
                <Pressable
                  style={{
                    borderColor: "#7c94b6",
                    borderRadius: 20,
                    marginRight: 8,
                    borderStyle: "dashed",
                    padding: 6,
                    borderWidth: 6,
                  }}
                  onPress={() => setCategory(cat.value)}
                >
                  <CustomText style={styles.categoryText}>{cat.key}</CustomText>
                </Pressable>
              </>
            ))}
        </ScrollView>
        {!!products && products?.length > 0 && (
          <CreateCardsList
            data={[
              ...products.filter((p) => p?.tags[0]?.tags.includes(category)),
            ]}
            vertical={true}
            columns={2}
            width="42%"
            margin={16}
          />
        )}
        <Footer />
      </ScrollView>
    </View>
  );
};

const mapStateToProps = (state) => ({
  products: state?.cms?.productList,
  loading: state?.notification?.loadingSpinner,
  dashboardData: state?.cms?.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (stop) => dispatch(getDashboard(stop)),
  getProductsData: (stop, id) => dispatch(getProducts(stop, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSubCategoryScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
    rowGap: 8,
  },
  categoryContainer: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
    marginRight: 8,
    justifyContent: "space-between",
    alignItems: "center",
    rowGap: 20,
    backgroundColor: "#f5e6eb",
  },
  mainHeading: {
    color: "#3878e2",
    fontSize: 28,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
  categoryText: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "white",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "#3878e2",
    fontSize: 16,
    fontWeight: "bold",
  },
});
