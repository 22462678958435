import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { AntDesign } from "@expo/vector-icons";

const ImagePlaceHolder = ({ uri, onPress }) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {uri ? (
        <Image source={{ uri }} style={{ width: "100%", aspectRatio: 1 / 1 }} />
      ) : (
        <AntDesign name="camera" size={24} />
      )}
    </Pressable>
  );
};

export default ImagePlaceHolder;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    aspectRatio: 1 / 1,
    backgroundColor: "#a2a2a2",
    borderRadius: 8,
    margin: 6,
    minWidth: 100,
  },
  heading: {
    fontSize: 18,
  },
});
