import { Pressable, StyleSheet, Text, View } from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { userActions } from "../store/slices/auth";
import { notificationActions } from "../store/slices/notification";
import { useEffect } from "react";
import CustomText from "../components/CustomText";

const LoginScreen = ({ otpSent, isLoggedIn, navigation, isNameRequired }) => {
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && isNameRequired) {
      navigation.replace("ProfileShort");
    }
  }, [isLoggedIn]);

  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <Entypo name="mobile" size={24} color="black" />
        <View>
          <CustomText style={styles.mainHeading}>Verify Phone no.</CustomText>
          <CustomText style={styles.secondaryHeading}>
            Please use your current number
          </CustomText>
        </View>
      </View>
      <View style={styles.middleContainer}>
        <CustomText style={styles.phoneHeading}>Phone Number</CustomText>
        <View style={styles.numberContainer}>
          <View style={styles.prefixContainer}>
            <CustomText style={{ fontWeight: "bold" }}>+91</CustomText>
          </View>
          <Input
            placeholder="Mobile"
            keyboardType="phone-pad"
            maxLength={10}
            // value={userData.mobile}
            editable={!otpSent}
            placeholderTextColor="#000"
            onChangeText={(e) => {
              setUserData((prev) => ({
                ...prev,
                mobile: e,
              }));
            }}
          />
        </View>
        {otpSent && (
          <>
            <CustomText style={styles.phoneHeading}>OTP</CustomText>
            <View style={{ height: 42, width: "100%" }}>
              <Input
                // label="OTP"
                placeholder="Enter OTP"
                keyboardType="phone-pad"
                placeholderTextColor="#000"
                onChangeText={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    password: e,
                  }));
                }}
              />
            </View>
          </>
        )}
      </View>
      <View style={styles.lowerContainer}>
        <Pressable
          style={styles.button}
          onPress={async () => {
            dispatch(notificationActions.toggleError());
            if (userData.mobile && userData.mobile.length === 10) {
              // localStorage.setItem("mobile", mobile);
              !otpSent
                ? await dispatch(generateOtp({ ...userData }))
                : userData.password && userData.password.length === 4
                ? await dispatch(verifyOtp({ ...userData }))
                : dispatch(notificationActions.toggleError("Enter valid OTP"));
              // dispatch(login({mobile}));
            } else {
              dispatch(
                notificationActions.toggleError("Invalid Mobile Number")
              );
            }
          }}
        >
          <CustomText style={styles.buttonText}>
            {otpSent ? "Verify" : "Get OTP"}
          </CustomText>
        </Pressable>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  otpSent: state.user.otpSent,
  isLoggedIn: state.user.isLoggedIn,
  isNameRequired: state.user.isNameRequired,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 80,
    rowGap: 20,
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 16,
  },
  mainHeading: {
    color: "#3878e2",
    fontSize: 28,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#7b7b7b",
    fontSize: 16,
  },
  middleContainer: {
    borderRadius: 20,
    alignItems: "flex-start",
    backgroundColor: "#ff6c94",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 28,
    justifyContent: "space-between",
  },
  prefixContainer: {
    backgroundColor: "#fff",
    padding: 12,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "white",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "#3878e2",
    fontSize: 16,
    fontWeight: "bold",
  },
});
