import { Pressable, StyleSheet, Text, View } from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, setName, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { notificationActions } from "../store/slices/notification";
import CustomText from "../components/CustomText";

const MiniLoginScreen = ({ profileData, token }) => {
  const [userData, setUserData] = useState({
    first_name: profileData?.first_name,
  });
  const dispatch = useDispatch();
  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>
          What should we call you?
        </CustomText>
      </View>
      <View style={styles.middleContainer}>
        <CustomText style={styles.phoneHeading}>Name</CustomText>
        <Input
          placeholder="Name"
          defaultValue={profileData?.first_name}
          onChangeText={(e) => {
            setUserData((prev) => ({
              ...prev,
              first_name: e,
            }));
          }}
        />
      </View>
      <View style={styles.lowerContainer}>
        <Pressable
          style={styles.button}
          onPress={async () => {
            dispatch(notificationActions.toggleError());
            if (userData.first_name && userData.first_name.length !== 0) {
              await dispatch(setName(token, { ...userData }));
            } else {
              dispatch(notificationActions.toggleError("Invalid Name"));
            }
          }}
        >
          <CustomText style={styles.buttonText}>Done</CustomText>
        </Pressable>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.user.profileData,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MiniLoginScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 80,
    rowGap: 20,
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 16,
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  middleContainer: {
    borderRadius: 20,
    alignItems: "flex-start",
    backgroundColor: "#ff6c94",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#3878e2",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
