import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  Platform,
  View,
  TextInput,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  MaterialIcons,
} from "@expo/vector-icons";
import * as Linking from "expo-linking";
import ListView from "../components/ListView";
import {
  placeOrder,
  validateOrder,
  completePayment,
} from "../store/actions/cms";
import { notificationActions } from "../store/slices/notification";
import { CommonActions } from "@react-navigation/native";
import CustomText from "../components/CustomText";

const CheckoutScreen = ({
  config,
  route,
  loading,
  validateOrder,
  token,
  placeOrder,
  completePayment,
  navigation,
}) => {
  const { templateId, productId } = route.params;

  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [text, setText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [coupon, setCoupon] = useState(false);

  const fetchData = async (coupon_code) => {
    const validateData = await validateOrder(token, {
      product_id: productId,
      template_data_id: templateId,
      coupon_code,
    });

    setData(validateData);
    if (!validateData?.coupon_data?.status && coupon_code) {
      dispatch(
        notificationActions.toggleError(validateData?.coupon_data?.message)
      );
    } else {
      setCoupon(validateData?.coupon_data?.code);
    }
  };

  useEffect(() => {
    if (!templateId || !productId) {
      setTimeout(() => navigation.replace("Dashboard"), 100);
    } else {
      fetchData();
    }
  }, []);

  const handleClearText = () => {
    setText("");
  };

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Checkout</CustomText>
      </View>
      <View style={styles.container}>
        {data && (
          <>
            <View
              style={{
                rowGap: 4,
              }}
            >
              <CustomText style={{ fontWeight: "bold", marginBottom: 2 }}>
                Order Summary
              </CustomText>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Total Amount</CustomText>
                <CustomText style={{ fontWeight: "bold" }}>
                  ₹{data.total}
                </CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Discount</CustomText>
                <CustomText style={{ fontWeight: "bold" }}>
                  ₹{data.discount}
                </CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Subtotal</CustomText>
                <CustomText style={{ fontWeight: "bold" }}>
                  ₹{data.subtotal}
                </CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText style={{ color: "#407ce4", fontWeight: "bold" }}>
                  Total Saved
                </CustomText>
                <CustomText style={{ fontWeight: "bold", color: "#407ce4" }}>
                  ₹{data.total_saved}
                </CustomText>
              </View>
              <View style={styles.divider}></View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText style={{ color: "#407ce4", fontWeight: "bold" }}>
                  Payable Amount
                </CustomText>
                <CustomText style={{ fontWeight: "bold", color: "#407ce4" }}>
                  ₹{data.total_payable}
                </CustomText>
              </View>
              <View style={styles.divider}></View>
            </View>

            <View style={styles.categoryContainer}>
              <View
                style={{
                  borderBottomWidth: 2,
                  borderBottomColor: isFocused ? "#389cf8" : "grey",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    height: 50,
                    backgroundColor: "white",
                    flex: 1,
                  }}
                >
                  <TextInput
                    style={{
                      flex: 1,
                      height: "100%",
                      color: isFocused ? "#389cf8" : "black",
                      caretColor: "#389cf8",
                      caretWidth: 2,
                      paddingHorizontal: 20,
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    }}
                    value={text}
                    onChangeText={setText}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder="Enter coupon code"
                  />

                  <Pressable onPress={handleClearText} style={{ padding: 10 }}>
                    <Entypo
                      name="cross"
                      size={20}
                      color={isFocused ? "#389cf8" : "grey"}
                    />
                  </Pressable>
                </View>
              </View>
              <Pressable
                style={styles.button}
                onPress={async () => {
                  if (text) {
                    fetchData(text);
                  } else {
                    dispatch(
                      notificationActions.toggleError(
                        "Please enter valid coupon code"
                      )
                    );
                  }
                }}
              >
                <AntDesign
                  name="checkcircle"
                  style={styles.buttonText}
                  size={24}
                />
                {/* <CustomText style={styles.buttonText}>Complete Payment</CustomText> */}
              </Pressable>
            </View>

            <View style={styles.lowerContainer}>
              <Pressable
                style={styles.button}
                onPress={async () => {
                  const orderData = await placeOrder(token, {
                    product_id: productId,
                    template_data_id: templateId,
                    coupon_code: coupon,
                  });
                  if (orderData.status === 1) {
                    if (orderData.status === 1) {
                      if (data.subtotal == 0) {
                        const paymentStatus = await completePayment(
                          token,
                          orderData.order_id
                        );
                        if (paymentStatus) {
                          navigation.replace("Fallback", {
                            orderId: orderData.order_id,
                          });
                        }
                      } else {
                        Linking.openURL(
                          `${config["app.payment.url"]}${orderData.order_id}`
                        );
                      }
                    }
                  }
                }}
              >
                <CustomText style={styles.buttonText}>
                  Complete Payment
                </CustomText>
              </Pressable>
            </View>
          </>
        )}
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notification?.loadingSpinner,
  config: state?.cms?.config,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  validateOrder: (token, data) => dispatch(validateOrder(token, data)),
  placeOrder: (token, data) => dispatch(placeOrder(token, data)),
  completePayment: (token, data) => dispatch(completePayment(token, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    padding: 16,
  },
  divider: {
    width: "100%",
    marginVertical: 2,
    height: StyleSheet.hairlineWidth,
    backgroundColor: "#a2a2a2",
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#3878e2",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 32,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },

  categoryContainer: {
    flexDirection: "row",
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    paddingVertical: 6,
    borderRadius: 6,
    columnGap: 20,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 6,
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
});
