import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { AntDesign, Ionicons, MaterialIcons } from "@expo/vector-icons";
import * as Linking from "expo-linking";

import {
  generatePdfLink,
  getOrders,
  getTemplateData,
  getTemplatePreData,
} from "../store/actions/cms";
import { notificationActions } from "../store/slices/notification";
import { generateShareContent } from "../utils/generateMessage";
import shareMessage from "../utils/shareMessage";
import BottomMenu from "../components/BottomMenu";
import CustomText from "../components/CustomText";

const OrderHistoryScreen = ({
  getData,
  loading,
  orderData,
  token,
  navigation,
  generatePdf,
  config,
  getTemplateData,
}) => {
  const dispatch = useDispatch();

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [message, setMessage] = useState("");

  const OpenMenu = () => {
    setIsMenuVisible(true);
  };

  const closeModal = () => {
    setIsMenuVisible(false);
    setMessage("");
  };

  useEffect(() => {
    getData(token);
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Order History</CustomText>
        <BottomMenu
          isMenuVisible={isMenuVisible}
          closeModal={closeModal}
          message={message}
        />
      </View>
      {orderData?.length === 0 ? (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <CustomText style={styles.mainHeading}>No orders yet</CustomText>
        </View>
      ) : (
        <View style={styles.container}>
          <ScrollView
            style={styles.container}
            showsVerticalScrollIndicator={false}
          >
            {orderData &&
              orderData.map((order) => (
                <Pressable
                  style={styles.middleContainer}
                  onPress={() => {
                    navigation.navigate("Pages", {
                      isOrderDetail: 1,
                      id: order?.items[0]?.product_id,
                      templateId: order?.items[0]?.template_data_id,
                    });
                  }}
                >
                  <View style={styles.flexContainer}>
                    <CustomText style={styles.phoneHeading}>
                      OrderId #{order?.order_id?.split("-")[0]}
                    </CustomText>
                    <CustomText style={styles.phoneHeading}>
                      ₹{order?.subtotal}
                    </CustomText>
                  </View>

                  <View
                    style={{
                      width: "100%",
                      columnGap: 5,
                      flexDirection: "row",
                    }}
                  >
                    <Pressable
                      style={styles.button}
                      onPress={async () => {
                        const tempData = await getTemplateData(
                          token,
                          order.items[0]?.template_data_id
                        );
                        // shareMessage(
                        setMessage(
                          generateShareContent(config, tempData.data, order)
                        );
                        OpenMenu();
                        // );
                      }}
                    >
                      <Ionicons
                        name="share-social-sharp"
                        size={16}
                        color="black"
                      />
                      <CustomText style={styles.buttonText}>Share</CustomText>
                    </Pressable>
                    <Pressable
                      style={styles.button}
                      onPress={async () => {
                        const res = await generatePdf(
                          order.payment_info[0]?.payment_gtw_id.split("_")[1]
                        );
                        if (res.status) {
                          Linking.openURL(res.url);
                        } else {
                          dispatch(
                            notificationActions.toggleError(
                              "Error generating Pdf"
                            )
                          );
                        }
                      }}
                    >
                      <AntDesign name="arrowdown" size={16} color="#000" />
                      <CustomText style={styles.buttonText}>
                        Download
                      </CustomText>
                    </Pressable>
                  </View>
                </Pressable>
              ))}
          </ScrollView>
        </View>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  orderData: state?.cms?.orders,
  config: state?.cms?.config,
  token: state?.user?.token,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getOrders(token)),
  generatePdf: (data) => dispatch(generatePdfLink(data)),
  getTemplateData: (token, id) => dispatch(getTemplateData(token, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 8,
  },
  flexContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 28,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  middleContainer: {
    borderRadius: 12,
    alignItems: "flex-start",
    backgroundColor: "#3878e2",
    padding: 8,
    marginBottom: 8,
    gap: 4,
  },
  phoneHeading: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "white",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    marginVertical: 4,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 8,
  },
  buttonText: {
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
  },
});
