import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import CustomText from "./CustomText";

const AudioPlaceHolder = ({ uri, onPress, title, deleteFunction }) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {uri ? (
        <>
          <CustomText>{title}</CustomText>
          <Pressable onPress={deleteFunction}>
            <MaterialIcons name="delete" size={24} color="black" />
          </Pressable>
        </>
      ) : (
        <>
          <CustomText>Choose Audio</CustomText>
          <AntDesign name="camera" size={24} />
        </>
      )}
    </Pressable>
  );
};

export default AudioPlaceHolder;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: 6,
    flexDirection: "row",
    width: "100%",
    borderWidth: 1,
    borderColor: "#f09aab",
    borderRadius: 8,
    margin: 6,
    minWidth: 100,
  },
  heading: {
    fontSize: 18,
  },
});
