import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  FlatList,
  ActivityIndicator,
  Image,
  TextInput,
} from "react-native";
import React, { Children, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getReviews, getTemplateMesasgeData } from "../store/actions/cms";
import ListView from "../components/ListView";
import { Feather, FontAwesome } from "@expo/vector-icons";
import BackdropModal from "../components/BackdropModal";
import { notificationActions } from "../store/slices/notification";
import { capitalize } from "lodash";
import CustomText from "../components/CustomText";

const ChooseFamilyTypeScreen = ({ route, navigation, templateId }) => {
  const { onModalClose } = route.params;

  useEffect(() => {
    if (!templateId) {
      setTimeout(() => navigation.navigate("Dashboard"), 100);
    }
  }, []);

  const handleAdd = (selectedValue) => {
    console.log(selectedValue);
    onModalClose(selectedValue);
    navigation.goBack();
  };

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>
          Which side is this invite for
        </CustomText>
      </View>
      <View style={styles.container}>
        <View style={{ flex: 1 }}>
          <Pressable
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: "10%",
            }}
            onPress={() => handleAdd("groom")}
          >
            <Image
              style={{ width: "50%", aspectRatio: 1, borderRadius: 20 }}
              source={{
                uri: "https://ewr1.vultrobjects.com/cdn-sei/original/21ff98eb-6ae6-4ca7-8fa6-495ab8e668aa.png",
              }}
            />
          </Pressable>
          <Pressable
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 20,
              paddingHorizontal: "10%",
            }}
            onPress={() => handleAdd("bride")}
          >
            <Image
              style={{ width: "50%", aspectRatio: 1, borderRadius: 20 }}
              source={{
                uri: "https://ewr1.vultrobjects.com/cdn-sei/original/67fa8378-1589-4c3d-b394-007ed5c69f12.png",
              }}
            />
          </Pressable>
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  templateId: state?.profile?.templateId,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getTemplateMesasgeData(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseFamilyTypeScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    marginTop: 16,
  },
  upperContainer: {
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  primaryHeading: {
    fontWeight: "bold",
    fontSize: 16,
  },
  categoryContainer: {
    flexDirection: "row",
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    paddingVertical: 6,
    columnGap: 20,
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 6,
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  modalHeading: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  input: {
    height: 40,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  lowerContainer: {
    height: 48,
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#3878e2",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
