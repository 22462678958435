import { Pressable, StyleSheet, Text, View } from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, setName, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { notificationActions } from "../store/slices/notification";
import CustomText from "../components/CustomText";

const EditProfileScreen = ({ profileData, token, navigation }) => {
  const [userData, setUserData] = useState({
    first_name: profileData?.first_name,
    email: profileData?.email,
  });
  const dispatch = useDispatch();
  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Profile</CustomText>
      </View>
      <View style={styles.container}>
        <View style={styles.middleContainer}>
          <CustomText style={styles.phoneHeading}>Name</CustomText>
          <Input
            placeholder="Name"
            defaultValue={profileData?.first_name}
            onChangeText={(e) => {
              setUserData((prev) => ({
                ...prev,
                first_name: e,
              }));
            }}
          />
          <CustomText style={styles.phoneHeading}>Email</CustomText>
          <Input
            placeholder="Email"
            defaultValue={profileData?.email}
            onChangeText={(e) => {
              setUserData((prev) => ({
                ...prev,
                email: e.target.value,
              }));
            }}
          />
          <CustomText style={styles.phoneHeading}>Mobile</CustomText>
          <Input
            placeholder="Mobile"
            defaultValue={profileData?.mobile}
            readOnly={true}
          />
        </View>
        <View style={styles.lowerContainer}>
          <Pressable
            style={styles.button}
            onPress={async () => {
              dispatch(notificationActions.toggleError());
              if (
                userData.first_name &&
                userData.first_name.length !== 0 &&
                (!userData.email || userData?.email.includes("@"))
              ) {
                await dispatch(setName(token, { ...userData }));
                navigation.pop();
              } else {
                dispatch(
                  notificationActions.toggleError("Invalid Name or Email")
                );
              }
            }}
          >
            <CustomText style={styles.buttonText}>Update Profile</CustomText>
          </Pressable>
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.user.profileData,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    rowGap: 20,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  middleContainer: {
    borderRadius: 20,
    alignItems: "flex-start",
    backgroundColor: "#3878e2",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#3878e2",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
