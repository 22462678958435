import { Pressable, StyleSheet, Text, View } from "react-native";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import WebView from "../components/WebView";
import CustomText from "../components/CustomText";

const ProductScreen = ({ route, config, navigation }) => {
  const { id, isOrderDetail, templateId } = route.params;

  const url = isOrderDetail
    ? `${config["app.template.base_url"]}${id}${
        templateId ? `?templateDataId=${templateId}` : ""
      }`
    : config[`app.cms.url.${id}`];

  useEffect(() => {
    if (!url) {
      setTimeout(() => {
        navigation.navigate("Dashboard");
      }, 100);
    }
  }, [url]);

  return (
    <>
      <View style={styles.container}>
        <WebView uri={url} />
      </View>
      {parseInt(isOrderDetail) === 2 && (
        <View style={styles.lowerContainer}>
          <View
            style={{ flex: 1, padding: 6, columnGap: 5, flexDirection: "row" }}
          >
            <Pressable
              style={styles.button}
              onPress={() => {
                navigation.navigate("SubmitReview", {
                  id,
                });
              }}
            >
              <CustomText style={styles.buttonText}>Review</CustomText>
            </Pressable>
            <Pressable
              style={styles.button}
              onPress={() => {
                navigation.navigate("SubmitReview", {
                  id,
                });
              }}
            >
              <CustomText style={styles.buttonText}>Rating</CustomText>
            </Pressable>
          </View>
        </View>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  config: state?.cms?.config,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  lowerContainer: {
    height: 48,
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#3878e2",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
