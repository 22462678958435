import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getTemplateFormData,
  getTemplatePreData,
  submitTemplateData,
} from "../store/actions/cms";
import Accordion from "../components/Accordian";
import { notificationActions } from "../store/slices/notification";
import { profileActions } from "../store/slices/profile";
import CustomText from "../components/CustomText";

const FormScreen = ({
  loading,
  getData,
  getPreData,
  formData,
  navigation,
  token,
  route,
  populateTemplateData,
  productData,
  templateVersion,
  createTemplate,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      // if (!formData) {
      dispatch(notificationActions.toggleSpinnerLoading(true));
      await Promise.all(
        // {
        //   true: [],
        //   [!formData && !edit]: [getData(token), getPreData(token)],
        //   [formData && !edit]: [getPreData(token)],
        //   [!formData && edit]: [getData(token)],
        // }[true]
        !formData && !route.params?.edit
          ? [getData(token), getPreData(token, false, templateVersion)]
          : !formData
          ? [getData(token)]
          : !route.params?.edit
          ? [getPreData(token, false, templateVersion)]
          : [dispatch(notificationActions.toggleSpinnerLoading(false))]
      );
      dispatch(notificationActions.toggleSpinnerLoading(false));
      // }
    };
    fetchData();
  }, []);

  const data =
    templateVersion === 1
      ? formData?.find(
          (item) => item.entity_id == "master.page.template_data.custom.form.1"
        )?.data?.pages
      : templateVersion === 3
      ? formData?.find(
          (item) => item.entity_id == "master.page.template_data.custom.form.3"
        )?.data?.pages
      : productData?.variant.map((prod) => ({
          title: prod.title,
          key: prod.title.toLowerCase(),
          fields: formData?.find(
            (item) =>
              item.entity_id ==
              `master.page.template_data.${prod.type.toLowerCase()}`
          )?.data?.fields,
        }));

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, marginBottom: 40 }}>
        <Accordion
          data={data}
          templateVersion={templateVersion}
          populateTemplateData={populateTemplateData}
        />
      </View>
      <Pressable
        style={styles.button}
        onPress={async () => {
          let res;
          if (route.params?.edit) {
            console.log(route.params?.edit);
            res = await createTemplate(token, route.params?.edit);
          } else {
            res = await createTemplate(token);
          }
          if (res.status) {
            if (route.params?.edit) {
              navigation.navigate("OrderHistory");
            } else {
              navigation.navigate("Product", {
                id: res.data.product_id,
                templateId: res.template_data_id,
              });
            }
          }
        }}
      >
        <CustomText style={styles.buttonText}>
          {route.params?.edit ? "SAVE" : "Create"}
        </CustomText>
      </Pressable>
    </View>
  );
};

const mapStateToProps = (state) => ({
  formData: state?.cms?.formData,
  productData: state?.cms?.product,
  templateId: state?.profile?.templateId,
  templateVersion: state?.profile?.templateVersion,
  populateTemplateData: state?.profile?.templateData,
  loading: state?.notification?.loadingSpinner,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getTemplateFormData(token)),
  getPreData: (token, edit, version) =>
    dispatch(getTemplatePreData(token, edit, version)),
  createTemplate: (token, edit) => dispatch(submitTemplateData(token, edit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormScreen);

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#3878e2",
    position: "absolute",
    bottom: 5,
    width: "100%",
    paddingVertical: 6,
    paddingHorizontal: 20,
    marginTop: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
});
