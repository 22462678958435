import {
  View,
  Text,
  StyleSheet,
  Platform,
  ActivityIndicator,
} from "react-native";
import React, { useEffect } from "react";
import { WebView as Wv } from "react-native-webview";
import { useState } from "react";

const WebView = ({ uri }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    console.log("loaded");
    setLoading(false);
  };

  if (Platform.OS === "web") {
    return (
      <View style={{ flex: 1 }}>
        {loading && (
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <ActivityIndicator size="large" color="#407ce4" />
          </View>
        )}

        <iframe
          src={uri}
          style={{
            flex: loading ? 0 : 1,
            borderWidth: 0,
            visibility: loading ? "hidden" : "visible",
          }}
          onLoad={handleLoad}
        />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <Wv
        source={{
          uri,
        }}
        style={{ flex: 1 }}
      />
    </View>
  );
};

export default WebView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
