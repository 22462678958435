import {
  ActivityIndicator,
  Image,
  Pressable,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { userActions } from "../store/slices/auth";
import { notificationActions } from "../store/slices/notification";
import { useEffect } from "react";
import ProfileList from "../components/ProfileList";
import * as Linking from "expo-linking";
import ImageSlider from "../components/ImageSlider";
import { getDashboard } from "../store/actions/cms";
import CategoryList from "../components/CategoryList";
import Footer from "../components/Footer";
import { profileActions } from "../store/slices/profile";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CustomText from "../components/CustomText";

const Dashboard = ({
  profileData,
  getData,
  dashboardData,
  loading,
  name,
  banner,
  navigation,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const redirectUrl = await AsyncStorage.getItem("redirectUrl");
      if (redirectUrl) {
        await AsyncStorage.removeItem("redirectUrl");
        Linking.openURL(redirectUrl);
      } else {
        if (!dashboardData) {
          getData();
        }
      }
    };
    fetchData();
  }, []);

  useFocusEffect(() => {
    dispatch(profileActions.resetProfileSelection());
  });

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ScrollView
        style={[styles.container]}
        showsVerticalScrollIndicator={false}
      >
        <Pressable
          style={({ pressed }) =>
            pressed
              ? [styles.upperContainer, styles.pressed]
              : styles.upperContainer
          }
          android_ripple={{ color: "#d9d9d9" }}
          onPress={() => navigation.navigate("Profile")}
        >
          <View>
            <CustomText style={styles.mainHeading}>Hello {name},</CustomText>
            <CustomText style={styles.secondaryHeading}>
              Explore new profile designs
            </CustomText>
          </View>
          <View
            style={{
              width: 44,
              aspectRatio: 1 / 1,
              backgroundColor: "#007fff",
              borderRadius: 22,
            }}
          >
            <Image
              source={{
                uri: "https://ewr1.vultrobjects.com/cdn-sei/original/f593e4ee-b7a3-4114-b0ba-5785ad60fc8c.png",
              }}
              style={{ width: "100%", height: "100%" }}
            />
          </View>
        </Pressable>

        {profileData && <ProfileList data={profileData} />}
        {banner && <ImageSlider data={banner} />}
        {dashboardData?.catgeory_display &&
          dashboardData?.catgeory_display?.length > 0 &&
          dashboardData?.showcase &&
          dashboardData?.catgeory_display.map((category) => {
            const data = dashboardData?.showcase.filter(
              (item) => item.cat_id == category.category_id
            );
            return (
              data &&
              data.length > 0 && (
                <CategoryList title={category.name} data={data} />
              )
            );
          })}
        <Footer />
      </ScrollView>
    </View>
  );
};

const mapStateToProps = (state) => ({
  name: state?.user?.profileData?.first_name,
  profileData: state?.cms?.dashboard?.category,
  banner: state?.cms?.lookup?.banner,
  dashboardData: state?.cms?.dashboard,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getDashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 8,
    backgroundColor: "white",
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: 16,
    padding: 16,
  },
  pressed: {
    opacity: 0.7,
    backgroundColor: "#d9d9d9",
  },
  mainHeading: {
    color: "#3878e2",
    fontSize: 28,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
  middleContainer: {
    alignItems: "center",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 28,
    justifyContent: "space-between",
  },
  prefixContainer: {
    backgroundColor: "#fff",
    padding: 12,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "white",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "#3878e2",
    fontSize: 16,
    fontWeight: "bold",
  },
});
