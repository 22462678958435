import React, { useState } from "react";
import { View, StyleSheet, Text, TextInput } from "react-native";

export default function Input({
  label,
  boxHeight = 42,
  onFocus = () => {},
  styleProp = {},
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <View
      style={{
        width: "100%",
        flex: 1,
        // backgroundColor: "black",
        borderRadius: 4,
        // overflow: "hidden",
        height: boxHeight,
        minHeight: boxHeight,
      }}
    >
      {/* {label && <CustomText style={styles.label}>{label}</CustomText>} */}
      <View
        style={[
          styles.inputContainer,
          {
            borderColor: isFocused ? "#7975b5" : "#f3f4fb",
            height: boxHeight,
            minHeight: boxHeight,
          },
        ]}
      >
        {/* <Icon type="font-awesome" name={iconName} style={styles.icon} /> */}
        <TextInput
          style={[
            styles.input,
            {
              height: boxHeight,
              minHeight: boxHeight,
            },
            styleProp,
          ]}
          placeholderTextColor="#f09aab"
          autoCorrect={false}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          {...props}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    marginVertical: 5,
    color: "#fff",
    fontSize: 16,

    fontWeight: "bold",
  },
  inputContainer: {
    backgroundColor: "#fff",
    flexDirection: "row",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    alignItems: "center",
  },
  input: {
    color: "#000",
    fontWeight: "bold",
    width: "100%",
    paddingHorizontal: 8,
  },
  error: {
    color: "red",
    fontSize: 12,
    marginTop: 7,
  },
});
