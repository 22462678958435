import {
  ActivityIndicator,
  Image,
  Pressable,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  Linking,
  View,
  Share,
} from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { userActions } from "../store/slices/auth";
import { notificationActions } from "../store/slices/notification";
import { useEffect } from "react";
import ProfileList from "../components/ProfileList";
import ImageSlider from "../components/ImageSlider";
import { getDashboard } from "../store/actions/cms";
import CategoryList from "../components/CategoryList";
import { useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import shareMessage from "../utils/shareMessage";
import CustomText from "../components/CustomText";

const ProfileScreen = ({ config, loading, name, navigation }) => {
  const dispatch = useDispatch();

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ScrollView
        style={[
          styles.container,
          // {
          //   ...Platform.select({
          //     web: {
          //       scrollbarWidth: "none",
          //       /* For Firefox */
          //       scrollbarColor: "transparent transparent",
          //       /* For Chrome, Safari, and Edge */
          //       "-ms-overflow-style": "none" /* For Internet Explorer/Edge */,
          //       "::-webkit-scrollbar": {
          //         display: "none" /* For Chrome, Safari, and Edge */,
          //       },
          //     },
          //   }),
          // },
        ]}
        // ref={scrollViewRef}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.mainContainer}>
          <View
            style={{
              width: "50%",
              aspectRatio: 1 / 1,
              backgroundColor: "#007fff",
              borderRadius: 480,
            }}
          >
            <Image
              source={{
                uri: "https://ewr1.vultrobjects.com/cdn-sei/original/f593e4ee-b7a3-4114-b0ba-5785ad60fc8c.png",
              }}
              style={{ width: "100%", height: "100%" }}
            />
          </View>
          <CustomText style={styles.mainHeading}>{name}</CustomText>
          {[
            {
              title: "User Details",
              subTitle: "Manage saved data, emails, phone, links...",
              onPress: () => {
                navigation.navigate("EditProfile");
              },
            },
            {
              title: "Order History",
              subTitle: "Past Orders",
              onPress: () => navigation.navigate("OrderHistory"),
            },
            {
              title: "Contact Us",
              subTitle: "Support, Email, Helpline and Chat!",
              onPress: () => {
                navigation.navigate("ContactUs");
              },
            },
            {
              title: "About Us",
              subTitle: "Everything you want to know about!",
              onPress: () =>
                navigation.navigate("Pages", {
                  id: "about_us",
                }),
            },
          ].map((item) => (
            <Pressable style={styles.middleContainer} onPress={item.onPress}>
              <CustomText style={styles.mainOptionHeading}>
                {item.title}
              </CustomText>
              <CustomText style={styles.secondaryOptionHeading}>
                {item.subTitle}
              </CustomText>
            </Pressable>
          ))}
          <CustomText style={styles.mainOptionHeading}>
            LOVING KHOJO RIGHT NOW?
          </CustomText>
          <Pressable
            onPress={() => {
              Linking.openURL(config["app.cms.url.play_store"]);
            }}
          >
            <CustomText
              style={[styles.secondaryOptionHeading, { color: "#407ce4" }]}
            >
              Rate this app now!
            </CustomText>
          </Pressable>
          <Pressable
            style={styles.button}
            onPress={() =>
              shareMessage(
                `${config["app.cms.text.share_text"]} ${config["app.cms.url.play_store"]}`
              )
            }
          >
            <Entypo name="share" size={16} color="white" />
            <CustomText style={styles.buttonText}>
              {" "}
              Share this app now
            </CustomText>
          </Pressable>
          <CustomText style={styles.secondaryOptionHeading}>
            App Ver: {config["app.user.android.build.version_no"]}
          </CustomText>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.upperContainer}>
          <Pressable
            onPress={() =>
              navigation.navigate("Pages", {
                id: "privacy",
              })
            }
          >
            <CustomText style={styles.mainOptionHeading}>
              Privacy Policy
            </CustomText>
          </Pressable>
          <Pressable>
            <CustomText
              style={styles.mainOptionHeading}
              onPress={() =>
                navigation.navigate("Pages", {
                  id: "tnc",
                })
              }
            >
              Terms & services
            </CustomText>
          </Pressable>
          <Pressable>
            <CustomText
              style={styles.mainOptionHeading}
              onPress={() =>
                navigation.navigate("Pages", {
                  id: "refund_policy",
                })
              }
            >
              Refund Policy
            </CustomText>
          </Pressable>

          <Pressable
            onPress={async () => {
              await AsyncStorage.removeItem("access_token");
              dispatch(userActions.logout());
            }}
          >
            <CustomText style={styles.mainOptionHeading}>LOGOUT</CustomText>
          </Pressable>
        </View>
      </ScrollView>
    </View>
  );
};

const mapStateToProps = (state) => ({
  name: state?.user?.profileData?.first_name,
  profileData: state?.cms?.lookup?.category,
  banner: state?.cms?.lookup?.banner,
  config: state?.cms?.config,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getDashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  divider: {
    width: "100%",
    height: StyleSheet.hairlineWidth,
    backgroundColor: "#a2a2a2",
  },
  mainContainer: {
    padding: 16,
    flex: 1,
    rowGap: 16,
    alignItems: "center",
  },
  upperContainer: {
    rowGap: 16,
    padding: 16,
    width: "100%",
  },
  pressed: {
    opacity: 0.7,
    backgroundColor: "#d9d9d9",
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  mainOptionHeading: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  secondaryOptionHeading: {
    color: "#252525",
    fontSize: 12,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
  middleContainer: {
    borderRadius: 20,
    width: "100%",
    alignItems: "flex-start",
    backgroundColor: "#add0e1",
    padding: 12,
    gap: 2,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  numberContainer: {
    flexDirection: "row",
    width: "100%",
    gap: 28,
    justifyContent: "space-between",
  },
  prefixContainer: {
    backgroundColor: "#fff",
    padding: 12,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#3878e2",
    borderWidth: 3,
    flexDirection: "row",
    justifyContent: "space-between",
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
