import { FontAwesome, Ionicons, MaterialIcons } from "@expo/vector-icons";
import React, { useState } from "react";
import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  StyleSheet,
  Pressable,
  Linking,
  TouchableWithoutFeedback,
} from "react-native";
import Input from "./Input";
import { notificationActions } from "../store/slices/notification";
import { useDispatch } from "react-redux";
import shareMessage from "../utils/shareMessage";
import CustomText from "./CustomText";

const BottomMenu = ({ isMenuVisible, closeModal, message }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [number, setNumber] = useState("");

  const handleSendSMS = (status, number) => {
    console.log(status, number);
    if (!status == 0) {
      if (number && number.length !== 10) {
        dispatch(notificationActions.toggleError("Invalid number"));
        return;
      }
      const smsUrl =
        status === 1
          ? `https://wa.me/${number}?text=${encodeURIComponent(message)}`
          : `sms:${number}?body=${encodeURIComponent(message)}`;
      Linking.openURL(smsUrl)
        .then(() => {
          console.log("SMS app opened");
        })
        .catch((err) => {
          console.error("Failed to open SMS app", err);
        });
    } else {
      shareMessage(message);
    }
  };

  return (
    <View style={styles.container}>
      <Modal animationType="slide" transparent={true} visible={isMenuVisible}>
        <TouchableOpacity
          style={styles.backdrop}
          activeOpacity={1}
          onPress={closeModal}
        >
          <View style={styles.container}>
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <CustomText
                  style={{ marginHorizontal: "auto", fontWeight: "bold" }}
                >
                  Send link to your friends and family
                </CustomText>
                {active !== 0 && (
                  <>
                    <CustomText style={styles.fieldTitle}>Number</CustomText>
                    <TouchableWithoutFeedback onPress={() => {}}>
                      <Input
                        value={number}
                        styleProp={{
                          borderColor: "#407ce4",
                          borderWidth: 1,
                          borderRadius: 4,
                        }}
                        onChangeText={(e) => setNumber(e)}
                        placeholder="Mobile"
                        keyboardType="phone-pad"
                        maxLength={10}
                      />
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => {}}>
                      <Input
                        multiline={true}
                        boxHeight={104}
                        readOnly
                        styleProp={{
                          borderColor: "#407ce4",
                          borderWidth: 1,
                          borderRadius: 4,
                        }}
                        value={message}
                      />
                    </TouchableWithoutFeedback>
                  </>
                )}
                <TouchableOpacity
                  onPress={() => {
                    handleSendSMS(active, number);
                  }}
                  style={[styles.button, { marginHorizontal: "auto" }]}
                >
                  <CustomText style={styles.buttonText}>
                    <Ionicons name="ios-send" size={16} color="white" /> Send
                  </CustomText>
                </TouchableOpacity>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Pressable
                    onPress={() => setActive(0)}
                    style={{
                      width: "30%",
                      alignItems: "center",
                      borderColor: "#407ce4",
                      backgroundColor: active === 0 ? "#407ce4" : "trasparent",
                      borderWidth: 1,
                      borderRadius: 6,
                      padding: 8,
                    }}
                  >
                    <Ionicons
                      name="qr-code-outline"
                      size={16}
                      color={active === 0 ? "white" : "#407ce4"}
                    />
                    <CustomText
                      style={{
                        color: active === 0 ? "white" : "#407ce4",
                        fontWeight: "bold",
                      }}
                    >
                      QR Code
                    </CustomText>
                  </Pressable>
                  <Pressable
                    onPress={
                      //   handleSendSMS.bind(
                      //   this,
                      //   `https://wa.me/?text=${encodeURIComponent(message)}`
                      // )
                      () => setActive(1)
                    }
                    style={{
                      width: "30%",
                      alignItems: "center",
                      backgroundColor: active === 1 ? "#407ce4" : "trasparent",
                      borderColor: "#407ce4",
                      borderWidth: 1,
                      borderRadius: 6,
                      padding: 8,
                    }}
                  >
                    <FontAwesome
                      name="envelope"
                      size={16}
                      color={active === 1 ? "white" : "#407ce4"}
                    />
                    <CustomText
                      style={{
                        color: active === 1 ? "white" : "#407ce4",
                        fontWeight: "bold",
                      }}
                    >
                      Whatsapp
                    </CustomText>
                  </Pressable>
                  <Pressable
                    onPress={
                      //   handleSendSMS.bind(
                      //   this,
                      //   `sms:?body=${encodeURIComponent(message)}`
                      // )
                      () => setActive(2)
                    }
                    style={{
                      width: "30%",
                      alignItems: "center",
                      backgroundColor: active === 2 ? "#407ce4" : "trasparent",
                      borderColor: "#407ce4",
                      borderWidth: 1,
                      borderRadius: 6,
                      padding: 8,
                    }}
                  >
                    <MaterialIcons
                      name="message"
                      size={16}
                      color={active === 2 ? "white" : "#407ce4"}
                    />
                    <CustomText
                      style={{
                        color: active === 2 ? "white" : "#407ce4",
                        fontWeight: "bold",
                      }}
                    >
                      Message
                    </CustomText>
                  </Pressable>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#407ce4",
    padding: 8,
    borderRadius: 5,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  modalContainer: {
    maxWidth: 480,
    flex: 1,
    justifyContent: "flex-end",
    width: "100%",
  },
  modalContent: {
    backgroundColor: "#b0d4e4",
    padding: 20,
    rowGap: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  fieldTitle: {
    fontSize: 14,
    color: "#407ce4",
    fontWeight: "bold",
  },
  closeButton: {
    alignSelf: "flex-end",
    color: "blue",
    marginBottom: 10,
  },
  backdrop: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "flex-end",
  },
});

export default BottomMenu;
