import {
  ActivityIndicator,
  Image,
  Pressable,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { userActions } from "../store/slices/auth";
import { notificationActions } from "../store/slices/notification";
import { useEffect } from "react";
import ProfileList from "../components/ProfileList";
import ImageSlider from "../components/ImageSlider";
import { getDashboard, getProducts } from "../store/actions/cms";
import CategoryList from "../components/CategoryList";
import Footer from "../components/Footer";
import CreateCardsList from "../components/CreateCardsList";
import CustomText from "../components/CustomText";

const ProfileCardsPage = ({
  getData,
  dashboardData,
  categories,
  navigation,
  loading,
  products,
  getProductsData,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      if (!dashboardData) {
        dispatch(notificationActions.toggleSpinnerLoading(true));
        await Promise.all([getData(1), getProductsData(1)]);
        dispatch(notificationActions.toggleSpinnerLoading(false));
      } else {
        if (!products) await getProductsData();
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ScrollView
        style={[
          styles.container,
          // {
          //   ...Platform.select({
          //     web: {
          //       scrollbarWidth: "none",
          //       /* For Firefox */
          //       scrollbarColor: "transparent transparent",
          //       /* For Chrome, Safari, and Edge */
          //       "-ms-overflow-style": "none" /* For Internet Explorer/Edge */,
          //       "::-webkit-scrollbar": {
          //         display: "none" /* For Chrome, Safari, and Edge */,
          //       },
          //     },
          //   }),
          // },
        ]}
        // ref={scrollViewRef}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flexDirection: "row",
            padding: 16,
            paddingBottom: 0,
            backgroundColor: "#abcdde",
            justifyContent: "center",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            marginHorizontal: 16,
          }}
        >
          <CustomText style={styles.secondaryHeading}>
            Choose your Invite category
          </CustomText>
        </View>
        <ScrollView
          style={{
            flexDirection: "row",
            padding: 16,
            backgroundColor: "#abcdde",
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            marginHorizontal: 16,
          }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {categories &&
            categories?.length > 0 &&
            categories.map((cat) => (
              <>
                <Pressable
                  style={styles.categoryContainer}
                  onPress={() =>
                    navigation.navigate("ProfileSubCategory", {
                      id: cat.category_id,
                    })
                  }
                >
                  <View
                    style={{
                      width: 100,
                      aspectRatio: 1 / 1,
                      backgroundColor: "#7c94b6",
                      borderRadius: 50,
                      borderWidth: 6,
                      borderColor: "#fc6a97",
                      borderStyle: "dashed",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      source={{
                        uri: cat.image_url,
                      }}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </View>
                  <CustomText style={styles.categoryText}>
                    {cat.name}
                  </CustomText>
                </Pressable>
              </>
            ))}
        </ScrollView>
        {categories &&
          categories?.length > 0 &&
          products &&
          products?.length > 0 &&
          categories?.map((cat) => {
            const data = products.filter(
              (prod) => prod.category[0]?.category_id === cat.category_id
            );
            return (
              <>
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    paddingVertical: 8,
                  }}
                >
                  <CustomText style={styles.categoryText}>OR</CustomText>
                </View>
                <CreateCardsList data={data} title={cat.name} />
              </>
            );
          })}
        <Footer />
      </ScrollView>
    </View>
  );
};

const mapStateToProps = (state) => ({
  categories: state?.cms?.dashboard?.category,
  dashboardData: state?.cms?.dashboard,
  products: state?.cms?.products,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (stop) => dispatch(getDashboard(stop)),
  getProductsData: (stop) => dispatch(getProducts(stop)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCardsPage);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
    rowGap: 8,
  },
  categoryContainer: {
    paddingVertical: 4,
    marginRight: 16,
    alignItems: "center",
    rowGap: 20,
    width: 130,
  },
  mainHeading: {
    color: "#3878e2",
    fontSize: 28,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#252525",
    fontSize: 16,
    fontWeight: "bold",
  },
  categoryText: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  button: {
    backgroundColor: "white",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "#3878e2",
    fontSize: 16,
    fontWeight: "bold",
  },
});
