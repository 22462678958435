import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  Platform,
} from "react-native";
import React from "react";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { profileActions } from "../store/slices/profile";
import CustomText from "./CustomText";

const CreateCardsList = ({ data, title, vertical, columns, width, margin }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  return (
    <View style={styles.container}>
      {title && (
        <View>
          <View style={styles.headingContainer}>
            <CustomText style={styles.heading}>{title}</CustomText>
            <CustomText style={styles.heading}>Explore more</CustomText>
          </View>
          <CustomText style={styles.secondaryHeading}>
            Create a different theme for the same profile already been.
          </CustomText>
        </View>
      )}
      <FlatList
        data={data}
        horizontal={!vertical}
        numColumns={columns}
        showsHorizontalScrollIndicator={false}
        renderItem={(itemData) => {
          return (
            <View
              style={[
                styles.categoryContainer,
                {
                  width: width ? width : 180,
                  margin: margin ? margin : 8,
                  ...Platform.select({
                    ios: {
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                      shadowOffset: { width: 0, height: 5 },
                      shadowOpacity: 1,
                      shadowRadius: 5,
                    },
                    // For Android
                    android: {
                      elevation: 15,
                    },
                  }),
                },
              ]}
            >
              <Image
                source={{ uri: itemData.item.images[0].url }}
                style={[
                  {
                    resizeMode: "cover",
                    width: "50%",
                    aspectRatio: 1 / 1.5,
                    // height: 147,
                  },
                ]}
              />
              <View
                style={{ width: "100%", columnGap: 5, flexDirection: "row" }}
              >
                <Pressable
                  style={styles.button}
                  onPress={() => {
                    navigation.navigate("Product", {
                      id: itemData.item.product_id,
                    });
                  }}
                >
                  <CustomText style={styles.buttonText}>View</CustomText>
                  <AntDesign name="eye" size={12} color="#fff" />
                </Pressable>
                <Pressable
                  style={styles.button}
                  onPress={() => {
                    dispatch(
                      profileActions.setTemplateId(itemData.item.product_id)
                    );
                    dispatch(
                      profileActions.setTemplateVersion(itemData.item.version)
                    );
                    navigation.navigate("Create");
                  }}
                >
                  <CustomText style={styles.buttonText}>Create</CustomText>
                  <AntDesign name="arrowright" size={12} color="#fff" />
                </Pressable>
              </View>
            </View>
          );
        }}
        // keyExtractor={(item) => {
        //   return item.profile_id;
        // }}
        alwaysBounceVertical={false}
      />
    </View>
  );
};

export default CreateCardsList;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    // paddingBottom: 16,
    rowGap: 12,
  },
  categoryContainer: {
    marginVertical: 16,
    padding: 12,
    rowGap: 12,
    alignItems: "center",
    backgroundColor: "#ffffff",
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
  },
  secondaryHeading: {
    color: "#a2a2a2",
    fontSize: 10,
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#3878e2",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    marginVertical: 4,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
});
