import {
  ActivityIndicator,
  Linking,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  AntDesign,
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import ListView from "../components/ListView";
import {
  generatePdfLink,
  getOrder,
  getTemplateData,
  validateOrder,
} from "../store/actions/cms";
import { generateShareContent } from "../utils/generateMessage";
import shareMessage from "../utils/shareMessage";
import CustomText from "../components/CustomText";

const CheckoutScreen = ({
  loading,
  token,
  getTemplateData,
  generatePdf,
  navigation,
  config,
  order,
}) => {
  useEffect(() => {
    if (!order) {
      setTimeout(() => navigation.replace("Dashboard"), 100);
    }
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Order Details</CustomText>
      </View>

      {/* {data && (
          <>
            <View
              style={{
                rowGap: 4,
              }}
            >
              <CustomText style={{ fontWeight: "bold", marginBottom: 2 }}>
                Order Summary
              </CustomText>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Total Amount</CustomText>
                <CustomText style={{ fontWeight: "bold" }}>₹{data.total}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Discount</CustomText>
                <CustomText style={{ fontWeight: "bold" }}>₹{data.discount}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText>Subtotal</CustomText>
                <CustomText style={{ fontWeight: "bold" }}>₹{data.subtotal}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText style={{ color: "#407ce4", fontWeight: "bold" }}>
                  Total Saved
                </CustomText>
                <CustomText style={{ fontWeight: "bold", color: "#407ce4" }}>
                  ₹{data.total_saved}
                </CustomText>
              </View>
              <View style={styles.divider}></View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText style={{ color: "#407ce4", fontWeight: "bold" }}>
                  Payable Amount
                </CustomText>
                <CustomText style={{ fontWeight: "bold", color: "#407ce4" }}>
                  ₹{data.total_payable}
                </CustomText>
              </View>
              <View style={styles.divider}></View>
            </View>
            <View style={styles.lowerContainer}>
              <Pressable
                style={styles.button}
                onPress={async () => {
                  const orderData = await placeOrder(token, {
                    product_id: productId,
                    template_data_id: templateId,
                  });
                  if (orderData.status === 1) {
                    navigation.navigate("OrderComplete", {
                      id: orderData.order_id,
                    });
                  }
                }}
              >
                <CustomText style={styles.buttonText}>Complete Payment</CustomText>
              </Pressable>
            </View>
          </>
        )} */}
      {order && (
        <View style={styles.container}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 32,
              borderWidth: 8,
              borderColor: "#ececec",
              borderRadius: "50%",
            }}
          >
            <AntDesign
              name={order?.payment_info?.is_paid ? "like1" : "dislike1"}
              size={60}
              color="#407ce4"
            />
          </View>
          <CustomText>
            <CustomText style={styles.secondaryOptionHeading}>
              {order?.payment_info?.is_paid ? "Congratulations!" : "Oh Noo..!!"}
            </CustomText>
            {order?.payment_info?.is_paid
              ? "Your order has been placed"
              : "The transaction has failed"}
          </CustomText>
          <CustomText>Order id : #{order.order_id.split("-")[0]}</CustomText>
          <CustomText style={styles.mainOptionHeading}>
            LOVING KHOJO RIGHT NOW?
          </CustomText>
          <Pressable
            onPress={() => {
              Linking.openURL(config["app.cms.url.play_store"]);
            }}
          >
            <CustomText
              style={[styles.secondaryOptionHeading, { color: "#407ce4" }]}
            >
              Rate this app now!
            </CustomText>
          </Pressable>

          {order?.payment_info?.is_paid && (
            <View
              style={{
                width: "100%",
                columnGap: 5,
                flexDirection: "row",
                marginVertical: 20,
              }}
            >
              <Pressable
                style={styles.button}
                onPress={async () => {
                  const tempData = await getTemplateData(
                    token,
                    order.items[0]?.template_data_id
                  );
                  shareMessage(
                    generateShareContent(config, tempData.data, order)
                  );
                }}
              >
                <Ionicons name="share-social-sharp" size={16} color="white" />
                <CustomText style={styles.buttonText}>Share</CustomText>
              </Pressable>
              <Pressable
                style={styles.button}
                onPress={async () => {
                  const res = await generatePdf(order.order_id);
                  if (res.status) {
                    Linking.openURL(res.url);
                  } else {
                    dispatch(
                      notificationActions.toggleError("Error generating Pdf")
                    );
                  }
                }}
              >
                <AntDesign name="arrowdown" size={16} color="white" />
                <CustomText style={styles.buttonText}>Download</CustomText>
              </Pressable>
            </View>
          )}

          {order?.payment_info?.is_paid && (
            <View
              style={{
                width: "100%",
                columnGap: 5,
                flexDirection: "row",
              }}
            >
              <Pressable
                style={styles.button}
                onPress={() => {
                  navigation.navigate("Pages", {
                    isOrderDetail: 1,
                    id: order?.order_id,
                  });
                }}
              >
                <CustomText style={styles.buttonText}>View Profile</CustomText>
              </Pressable>
            </View>
          )}
        </View>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notification?.loadingSpinner,
  order: state?.cms?.order,
  config: state?.cms?.config,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: (token, data) => dispatch(getOrder(token, data)),
  generatePdf: (data) => dispatch(generatePdfLink(data)),
  getTemplateData: (token, id) => dispatch(getTemplateData(token, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 12,
    padding: 16,
    alignItems: "center",
  },
  divider: {
    width: "100%",
    marginVertical: 2,
    height: StyleSheet.hairlineWidth,
    backgroundColor: "#a2a2a2",
  },

  mainOptionHeading: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  secondaryOptionHeading: {
    color: "#252525",
    fontSize: 12,
    fontWeight: "bold",
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#407ce4",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    marginVertical: 4,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 8,
  },
  buttonText: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
});
