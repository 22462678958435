import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import WebView from "../components/WebView";
import ListView from "../components/ListView";
import { profileActions } from "../store/slices/profile";

const ProfileCategoryScreen = ({ categoryData, navigation }) => {
  const dispatch = useDispatch();

  const handleCategorySelection = (id) => {
    if (id) {
      navigation.navigate("ProfileSubCategory", {
        id: id,
      });
    }
  };
  return (
    <ListView
      data={categoryData}
      title="What type of profile you want?"
      uniqueKey="profile_category_id"
      onPress={handleCategorySelection}
    />
  );
};

const mapStateToProps = (state) => ({
  categoryData: state?.cms?.lookup?.category,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCategoryScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  upperContainer: {
    flex: 5,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 20,
    fontWeight: "bold",
  },
  lowerContainer: {
    padding: 16,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#3878e2",
    width: "100%",
    paddingVertical: 6,
    paddingHorizontal: 20,
    marginTop: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
});
