import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: null,
  lookup: null,
  dashboard: null,
  product: null,
  productList: null,
  products: null,
  orders: [],
  order: null,
  reviews: [],
  formData: null,

  formMessageData: null,
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setLookup: (state, action) => {
      state.lookup = action.payload;
    },
    setDashboardData: (state, action) => {
      state.dashboard = action.payload;
    },
    setOrderData: (state, action) => {
      state.order = action.payload;
    },
    setProductData: (state, action) => {
      state.product = action.payload;
    },
    setProductsList: (state, action) => {
      state.products = action.payload;
    },
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
    setOrdersList: (state, action) => {
      state.orders = action.payload;
    },
    setReviewsList: (state, action) => {
      state.reviews = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setFormMessageData: (state, action) => {
      state.formMessageData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const cmsActions = cmsSlice.actions;

export default cmsSlice.reducer;
