import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import Input from "../components/Input";
import { generateOtp, setName, verifyOtp } from "../store/actions/auth";
import { useState } from "react";
import { notificationActions } from "../store/slices/notification";
import WebView from "../components/WebView";
import { getProduct } from "../store/actions/cms";
import { profileActions } from "../store/slices/profile";
import CustomText from "../components/CustomText";

const ProductScreen = ({
  getData,
  route,
  loading,
  productData,
  baseUrl,
  navigation,
  setTemplateId,
  setTemplateVersion,
}) => {
  const { id, templateId } = route.params;

  useEffect(() => {
    getData(id);
  }, [id]);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <WebView
          uri={`${baseUrl}${id}?templateDataId=${
            templateId ? templateId : productData?.template_data_id
          }`}
        />
      </View>
      <View style={styles.lowerContainer}>
        <CustomText style={styles.phoneHeading}>{productData?.name}</CustomText>
        <CustomText style={styles.phoneHeading}>
          ₹{productData?.variant[0]?.price}
        </CustomText>
        <Pressable
          style={styles.button}
          onPress={() => {
            setTemplateId(id);
            setTemplateVersion(productData?.version);
            if (templateId) {
              navigation.navigate("Checkout", {
                productId: id,
                templateId,
              });
            } else {
              navigation.navigate("Create");
            }
          }}
        >
          <CustomText style={styles.buttonText}>Create</CustomText>
        </Pressable>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  productData: state?.cms?.product,
  baseUrl: state?.cms?.config["app.template.base_url"],
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (id) => dispatch(getProduct(id)),
  setTemplateId: (id) => dispatch(profileActions.setTemplateId(id)),
  setTemplateVersion: (id) => dispatch(profileActions.setTemplateVersion(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  upperContainer: {
    flex: 5,
  },
  phoneHeading: {
    color: "#000",
    fontSize: 20,
    fontWeight: "bold",
  },
  lowerContainer: {
    padding: 16,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#3878e2",
    width: "100%",
    paddingVertical: 6,
    paddingHorizontal: 20,
    marginTop: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
});
