import { Share } from "react-native";

const shareMessage = (message) => {
  Share.share({
    message: message, // Your message to share
  })
    .then((result) => {
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // Shared successfully
          console.log("Shared successfully");
        } else {
          // Shared
          console.log("Shared");
        }
      } else if (result.action === Share.dismissedAction) {
        // Dismissed
        console.log("Dismissed");
      }
    })
    .catch((error) => console.error("Error sharing:", error));
};

export default shareMessage;
