function isNumeric(input) {
  return /^\d+$/.test(input);
}

const arrayToObject = (arr) => {
  const obj = {};

  arr
    .filter((item) => !!item)
    .forEach((element, index) => {
      obj[index] = element;
    });

  return obj;
};

function objectToArray(obj) {
  const arr = [];

  Object.keys(obj).forEach((key) => {
    arr.push(obj[key]);
  });

  return arr;
}

export const convertArraysToObject = (obj, version) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (Array.isArray(obj[key])) {
        obj[key] = version === 2 ? obj[key][0] : arrayToObject(obj[key]);
      }
    }
  }
  return obj;
};

export const convertObjectsToArray = (obj, version) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        obj[key] =
          version === 2
            ? obj[key]["title"]
              ? obj[key]
              : [obj[key]]
            : obj[key][0]
            ? objectToArray(obj[key])
            : obj[key];
      }
    }
  }
  return obj;
};
