import { userActions } from "../slices/auth";
import { notificationActions } from "../slices/notification";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const getProfile = (token) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleLoading(true));
    const res = await fetch(
      `https://api.smarteinvites.in/v1/auth/profile/user`,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // console.log(res)
    if (!res.ok) {
      dispatch(notificationActions.toggleLoading(false));
      dispatch(notificationActions.toggleError("Failed fetching user"));
      return;
    } else {
      const data = await res.json();
      dispatch(
        userActions.auth({
          profileData: data,
          token,
        })
      );
      dispatch(notificationActions.toggleLoading(false));
    }
  };
};

export const generateOtp = (data) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleLoading(true));
    const body = JSON.stringify(data);
    const sendRequest = async () => {
      const res = await fetch(
        `https://api.smarteinvites.in/v1/auth/login/phone`,
        {
          body,
          headers: { "Content-Type": "application/json" },
          method: "POST",
        }
      );
      if (!res.ok) {
        dispatch(notificationActions.toggleLoading(false));
        dispatch(notificationActions.toggleError("Sometihng went very wrong"));

        return;
      }
      const data = await res.json();

      return data;
    };

    const res = await sendRequest();
    if (res?.status) {
      dispatch(userActions.toggleOtpSent(true));
    } else {
      dispatch(notificationActions.toggleError(res?.message));
    }
    dispatch(notificationActions.toggleLoading(false));
  };
};

export const verifyOtp = (data) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleLoading(true));
    const body = JSON.stringify(data);
    const sendRequest = async () => {
      const res = await fetch(
        `https://api.smarteinvites.in/v1/auth/login/phone/verify`,
        {
          body,
          headers: { "Content-Type": "application/json" },
          method: "POST",
        }
      );
      if (!res.ok) {
        const data = await res.json();

        dispatch(notificationActions.toggleLoading(false));
        dispatch(notificationActions.toggleError(data.detail));

        return;
      }
      const data = await res.json();

      return data;
    };

    const res = await sendRequest();
    if (res?.access_token) {
      dispatch(notificationActions.toggleLoading(false));
      AsyncStorage.setItem(
        "access_token",
        `${res?.token_type} ${res?.access_token}`
      );
      dispatch(getProfile(`${res?.token_type} ${res?.access_token}`));
    }
  };
};

export const setName = (token, name) => {
  return async (dispatch) => {
    dispatch(notificationActions.toggleLoading(true));
    const sendRequest = async () => {
      const body = JSON.stringify(name);
      const res = await fetch(
        `https://api.smarteinvites.in/v1/auth/profile/user/name`,
        {
          headers: { Authorization: `${token}` },
          method: "PUT",
          body,
        }
      );
      if (!res.ok) {
        const data = await res.json();
        dispatch(notificationActions.toggleLoading(false));
        dispatch(notificationActions.toggleError("Something went wrong"));
        return;
      }
      const data = await res.json();
      return data;
    };

    const res = await sendRequest();
    if (res) {
      dispatch(notificationActions.toggleLoading(false));
      // dispatch(userActions.)
      dispatch(userActions.setProfileData(res));
    }
  };
};
