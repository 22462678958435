import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

const StarRating = ({ rating, onRatingChange }) => {
  const [selectedRating, setSelectedRating] = useState(rating);

  const handleRating = (selectedValue) => {
    setSelectedRating(selectedValue);
    onRatingChange(selectedValue);
  };

  const renderStars = () => {
    const stars = [];
    const maxRating = 5;

    for (let i = 1; i <= maxRating; i++) {
      const iconName = i <= selectedRating ? "star" : "star-o"; // Use filled or outline star based on selected or unselected state
      stars.push(
        <TouchableOpacity
          key={`star-${i}`}
          style={{ marginRight: 4 }}
          onPress={() => handleRating(i)}
        >
          <FontAwesome name={iconName} size={30} color="gold" />
        </TouchableOpacity>
      );
    }

    return stars;
  };

  return <View style={{ flexDirection: "row" }}>{renderStars()}</View>;
};

export default StarRating;
