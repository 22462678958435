export const generateShareContent = (config, templateData, order) => {
  let shareContent = `${config["app.cms.template_share_text"]}`;

  let GROOM_NAME =
    templateData[
      templateData.version === 2
        ? "basic_family_profile_groom"
        : "family_profile_groom"
    ].title;
  let BRIDE_NAME =
    templateData[
      templateData.version === 2
        ? "basic_family_profile_bride"
        : "family_profile_bride"
    ].title;
  let WEDDING_DATE =
    templateData[
      templateData.version === 2 ? "basic_basic_date" : "basic_date"
    ];
  let TEMPLATE_URL = `${config["app.template.base_url"]}i/${
    order.payment_info[0]?.payment_gtw_id.split("_")[1]
  }`;
  shareContent = shareContent.replace("GROOM_NAME", GROOM_NAME);
  shareContent = shareContent.replace("BRIDE_NAME", BRIDE_NAME);
  shareContent = shareContent.replace("WEDDING_DATE", WEDDING_DATE);
  shareContent = shareContent.replace("TEMPLATE_URL", TEMPLATE_URL);

  let categoryName =
    order["items"][0]["product"]["category"]["data"]["name"].toString();
  shareContent = shareContent.replace("CATEGORY_NAME", categoryName);

  return shareContent;
};
