import {
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
  Linking,
  View,
} from "react-native";
import React, { Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import * as Progress from "react-native-progress";
import TopNotification from "../components/Notification";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AppLoading from "expo-app-loading";
import { getProfile } from "../store/actions/auth";
import { useState } from "react";
import { useEffect } from "react";
import * as Font from "expo-font";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getConfig, getLookup } from "../store/actions/cms";
import { NavigationContainer } from "@react-navigation/native";
// import * as Linking from "expo-linking";
// const NotLoggedInNavigator = React.lazy(() =>
//   import("../routes/NotLoggedInNavigator")
// );

// const LoggedInNavigator = React.lazy(() =>
//   import("../routes/LoggedInNavigator")
// );
import NotLoggedInNavigator from "../routes/NotLoggedInNavigator";
import LoggedInNavigator from "../routes/LoggedInNavigator";

const Stack = createNativeStackNavigator();

const LoadingComponent = () => (
  <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
    <ActivityIndicator size="large" color="#407ce4" />
  </View>
);

const CoreScreen = ({ notification, isLoggedIn, isNameRequired }) => {
  const linking = {
    prefixes: [
      "https://web-view-native.onrender.com",
      "http://localhost:19006",
    ],
    config: {
      screens: {
        Login: "login",
        ProfileShort: "profile-short",
        Product: "product/:id",
        Dashboard: "dashboard",
        Profile: "profile",
        EditProfile: "profile/edit",
        ProfileCards: "profile-cards",
        ProfileCategory: "profile-category",
        ProfileSubCategory: "profile-category/:id/sub-category",
        Create: "create",
        Fallback: "payment/fallback",
        Review: "order/:id/review",
        SubmitReview: "order/:id/submit-review",
        Pages: "pages/:id",
        OrderComplete: "order-complete",
        Checkout: "order-checkout/:templateId",
        ContactUs: "contact-us",
        OrderHistory: "order-history",
        ChooseSubInput: "choose-sub-input/:key",
        ChooseFamilyType: "choose-family-type",
        ChooseSubSelection: "choose-sub-selection/:key",
        ChooseFile: "choose-file/:key",
      },
    },
  };
  const [isTryingLogin, setIsTryingLogin] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchToken() {
      const initialUrl = await Linking.getInitialURL();
      console.log(initialUrl, "inint");
      const token = await AsyncStorage.getItem("access_token");
      const requests = [dispatch(getLookup()), dispatch(getConfig())];
      if (token) {
        requests.push(dispatch(getProfile(token)));
      } else {
        if (
          !initialUrl.includes("dashboard") &&
          !initialUrl.includes("login") &&
          !initialUrl.includes("short") &&
          !initialUrl.split("/").length === 1
        ) {
          AsyncStorage.setItem("redirectUrl", initialUrl);
        }
      }
      await Promise.all(requests);
      setIsTryingLogin(false);
    }

    fetchToken();
  }, []);

  useEffect(() => {
    const fetchFont = async () => {
      Font.loadAsync({
        SFPro: require("../assets/fonts/SFPro.ttf"),
      });
    };
    fetchFont();
  }, []);

  if (isTryingLogin) {
    if (Platform.OS === "web") {
      return (
        <View style={[{ ...styles.container }, { backgroundColor: "white" }]}>
          <Image
            source={{
              uri: require("../assets/splash.png"),
            }}
            style={{ width: "100%", height: "100%" }}
          />
        </View>
      );
    }
    return <AppLoading />;
  }

  return (
    <NavigationContainer
      linking={linking}
      theme={{
        colors: {
          background: "white", // Set the background color to white globally
        },
      }}
    >
      <View style={styles.container}>
        {notification?.loading && (
          <Progress.Bar
            animated
            indeterminate
            unfilledColor="#90caf9"
            color="#2196f3"
            borderWidth={0}
            width={null}
            useNativeDriver
            borderRadius={0}
          />
        )}
        {notification?.show && !!notification?.text && (
          <TopNotification message={notification?.text} />
        )}
        {/* <Suspense fallback={<LoadingComponent />}> */}
        {!isLoggedIn || isNameRequired ? (
          <NotLoggedInNavigator />
        ) : (
          <LoggedInNavigator />
        )}
        {/* </Suspense> */}
      </View>
    </NavigationContainer>
  );
};

const mapStateToProps = (state) => ({
  notification: state.notification,
  isLoggedIn: state.user.isLoggedIn,
  isNameRequired: state.user.isNameRequired,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CoreScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
