import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator, View } from "react-native";
import { getOrder } from "../store/actions/cms";
import { CommonActions } from "@react-navigation/native";
const PaymentFallback = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.user?.token);

  useEffect(() => {
    const getTransactionStatus = async () => {
      if (route.params.orderId) {
        const data = await dispatch(getOrder(token, route.params.orderId));
        if (data) {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [
                {
                  name: "OrderComplete",
                },
              ],
            })
          );
        }
      }
    };
    getTransactionStatus();
    // const timer = setTimeout(getTransactionStatus, 5000);
    // return () => {
    //   if (timer) clearTimeout(timer);
    // };
    // console.log(orderId, "outside function");
  }, [route.params.orderId]);
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <ActivityIndicator size="large" color="#407ce4" />
    </View>
  );
};

export default PaymentFallback;
