import { Pressable, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import ListView from "../components/ListView";
import StarRating from "../components/StarRating";
import Input from "../components/Input";
import { notificationActions } from "../store/slices/notification";
import { submitReview } from "../store/actions/cms";
import CustomText from "../components/CustomText";

const SubmitReviewScreen = ({ route, navigation, loading, token }) => {
  const { id } = route.params;
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    review: "",
    rating: 0,
    payment_code: id,
  });

  const handleRatingChange = (value) => {
    setUserData((prev) => ({
      ...prev,
      rating: value,
    }));
  };

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>Review</CustomText>
      </View>
      <View style={styles.container}>
        <StarRating
          rating={userData.rating}
          onRatingChange={handleRatingChange}
        />
        <View style={styles.middleContainer}>
          <CustomText style={styles.phoneHeading}>Review</CustomText>
          <Input
            placeholder="Review"
            multiline={true}
            boxHeight={104}
            onChangeText={(e) => {
              setUserData((prev) => ({
                ...prev,
                review: e,
              }));
            }}
          />
        </View>
        <View style={styles.lowerContainer}>
          <Pressable
            style={styles.button}
            onPress={async () => {
              if (!loading) {
                dispatch(notificationActions.toggleError());
                if (
                  userData.review &&
                  userData.review.length !== 0 &&
                  userData.rating !== 0
                ) {
                  const data = await dispatch(
                    submitReview(token, { ...userData })
                  );
                  if (data.order_rating_review_id) {
                    console;
                    navigation.navigate("Dashboard");
                  }
                } else if (!userData.review || userData.review.length === 0) {
                  dispatch(
                    notificationActions.toggleError("Please enter review")
                  );
                } else {
                  dispatch(
                    notificationActions.toggleError("Please give rating")
                  );
                }
              }
            }}
          >
            <CustomText style={styles.buttonText}>Submit</CustomText>
          </Pressable>
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notification?.loading,
  token: state?.user?.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitReviewScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 16,
    paddingTop: 40,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#000",
    fontSize: 24,
    fontWeight: "bold",
  },
  middleContainer: {
    borderRadius: 20,
    width: "100%",
    marginTop: 20,
    alignItems: "flex-start",
    backgroundColor: "#3878e2",
    padding: 16,
    gap: 16,
  },
  phoneHeading: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  lowerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#3878e2",
    borderWidth: 3,
    borderColor: "#3878e2",
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 20,
    margin: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
