import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Platform,
  FlatList,
  ActivityIndicator,
  Image,
  TextInput,
} from "react-native";
import React, { Children, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getReviews, getTemplateMesasgeData } from "../store/actions/cms";
import ListView from "../components/ListView";
import { Feather, FontAwesome } from "@expo/vector-icons";
import BackdropModal from "../components/BackdropModal";
import { notificationActions } from "../store/slices/notification";
import { capitalize } from "lodash";
import CustomText from "../components/CustomText";

const ChooseSubSelectionScreen = ({
  templateMessageData,
  route,
  token,
  categoryId,
  loading,
  navigation,
  templateId,
  getData,
}) => {
  const { key, onModalClose } = route.params;
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [children, setChildren] = useState();
  const [qiType, setQiType] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    if (!categoryId || !templateId) {
      setTimeout(() => navigation.navigate("Dashboard"), 100);
    } else {
      if (!templateMessageData) getData(token);
    }
  }, [categoryId, templateMessageData]);

  const handleAdd = (selectedValue) => {
    console.log(selectedValue);
    if (!selectedValue && !value) {
      setModalVisible(false);
      dispatch(notificationActions.toggleError("Enter Value"));
    } else {
      onModalClose({
        type: qiType,
        value: selectedValue ? selectedValue : value,
      });
      navigation.goBack();
    }
  };

  const handleSelection = (data, title) => {
    setQiType(title);
    if (data.selection) {
      setChildren(data.childrens);
    } else {
      setChildren();
    }
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };
  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" color="#407ce4" />
      </View>
    );
  }

  return (
    <>
      <View style={styles.upperContainer}>
        <CustomText style={styles.mainHeading}>
          {capitalize(key.replace("_", " "))}
        </CustomText>
      </View>
      <View style={styles.container}>
        <View style={{ flex: 1 }}>
          <FlatList
            data={templateMessageData
              ?.filter(
                (item) =>
                  item.template_type === key &&
                  item.data.profile_category_id === categoryId
              )
              .map((item) => item.data)}
            showsVerticalScrollIndicator={false}
            renderItem={(itemData) => {
              return (
                <Pressable
                  style={styles.categoryContainer}
                  onPress={() =>
                    handleSelection(itemData.item, itemData.item.title)
                  }
                >
                  <Feather name="list" size={24} color="f5f5f5" />
                  <View style={{ columnGap: 4 }}>
                    <CustomText style={{ fontWeight: "bold" }}>
                      {itemData.item.title}
                    </CustomText>
                  </View>
                </Pressable>
              );
            }}
            keyExtractor={(item) => {
              return item["template_message_id"];
            }}
            alwaysBounceVertical={false}
          />
        </View>
      </View>
      <BackdropModal
        visible={modalVisible}
        onClose={handleModalClose}
        Content={
          <>
            {children ? (
              <FlatList
                data={children}
                showsVerticalScrollIndicator={false}
                renderItem={(itemData) => {
                  return (
                    <Pressable
                      style={styles.categoryContainer}
                      onPress={() => {
                        handleAdd(itemData.item.value);
                      }}
                    >
                      <FontAwesome name="star" size={24} color="#a2a2a2" />
                      <View style={{ columnGap: 4 }}>
                        <CustomText style={{ fontWeight: "bold" }}>
                          {itemData.item.value}
                        </CustomText>
                      </View>
                    </Pressable>
                  );
                }}
                keyExtractor={(item) => {
                  return item["template_message_id"];
                }}
                alwaysBounceVertical={false}
              />
            ) : (
              <>
                <CustomText style={styles.modalHeading}>Value</CustomText>
                <TextInput
                  placeholder="Enter something..."
                  onChangeText={setValue}
                  value={value}
                  style={styles.input}
                />
                <View style={styles.lowerContainer}>
                  <View
                    style={{
                      flex: 1,
                      padding: 6,
                      columnGap: 5,
                      flexDirection: "row",
                    }}
                  >
                    <Pressable style={styles.button} onPress={handleModalClose}>
                      <CustomText style={styles.buttonText}>Cancel</CustomText>
                    </Pressable>
                    <Pressable
                      style={styles.button}
                      onPress={() => handleAdd()}
                    >
                      <CustomText style={styles.buttonText}>Add</CustomText>
                    </Pressable>
                  </View>
                </View>
              </>
            )}
          </>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  templateMessageData: state?.cms?.formMessageData,
  token: state?.user?.token,
  categoryId: state?.profile?.categoryId,
  templateId: state?.profile?.templateId,
  loading: state?.notification?.loadingSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (token) => dispatch(getTemplateMesasgeData(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseSubSelectionScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 4,
    marginTop: 16,
  },
  upperContainer: {
    padding: 8,
    backgroundColor: "#add0e1",
    alignItems: "center",
    justifyContent: "center",
  },
  mainHeading: {
    color: "#000",
    fontSize: 28,
    fontWeight: "bold",
  },
  primaryHeading: {
    fontWeight: "bold",
    fontSize: 16,
  },
  categoryContainer: {
    flexDirection: "row",
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    paddingVertical: 6,
    columnGap: 20,
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 6,
    ...Platform.select({
      web: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
      android: {
        elevation: 10,
      },
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.16)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 4,
      },
    }),
  },
  modalHeading: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  input: {
    height: 40,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  lowerContainer: {
    height: 48,
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#3878e2",
    flexDirection: "row",
    flex: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    justifyContent: "center",
    columnGap: 2,
    alignItems: "center",
    borderRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
});
