import { configureStore } from "@reduxjs/toolkit";
import notificationSlice from "./slices/notification";
import userSlice from "./slices/auth";
import cmsSlice from "./slices/cms";
import profileSlice from "./slices/profile";

export default configureStore({
  reducer: {
    notification: notificationSlice,
    user: userSlice,
    cms: cmsSlice,
    profile: profileSlice,
  },
});
