import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  isNameRequired: false,
  token: null,
  profileData: {},
  otpSent: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    auth: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.profileData = action.payload.profileData;
    },
    toggleOtpSent: (state, action) => {
      state.isNameRequired = true;
      state.otpSent = true;
    },
    setProfileData: (state, action) => {
      state.isNameRequired = false;
      state.profileData = action.payload;
    },
    logout: (state, action) => {
      //   localStorage.removeItem("access_token");
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const userActions = userSlice.actions;

export default userSlice.reducer;
