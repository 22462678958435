import React from "react";
import Dashboard from "../screens/Dashboard";

import ProductScreen from "../screens/ProductScreen";
import ProfileCardsPage from "../screens/ProfileCardsPage";
import ProfileCategoryScreen from "../screens/ProfileCategoryScreen";
import ProfileSubCategoryScreen from "../screens/ProfileSubCategoryScreen";
import FormScreen from "../screens/FormScreen";
import { AntDesign } from "@expo/vector-icons";
import PagesScreen from "../screens/PagesScreen";
import ProfileScreen from "../screens/ProfileScreen";

import EditProfileScreen from "../screens/EditProfileScreen";
import ContactUsScreen from "../screens/ContactUsScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Pressable } from "react-native";
import OrderHistoryScreen from "../screens/OrderHistoryScreen";
import ReviewScreen from "../screens/ReviewScreen";
import SubmitReviewScreen from "../screens/SubmitReviewScreen";
import ChooseSubInputScreen from "../screens/ChooseSubInputScreen";
import ChooseSubSelectionScreen from "../screens/ChooseSubSelectionScreen";
import ChooseFileScreen from "../screens/ChooseFileScreen";
import CheckoutScreen from "../screens/CheckoutScreen";
import OrderCompleteScreen from "../screens/OrderCompleteScreen";
import PaymentFallback from "../screens/PaymentFallback";
import ChooseFamilyType from "../screens/ChooseFamilyType";

const Stack = createNativeStackNavigator();
const LoggedInNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        headerTitle: "",
        headerStyle: {
          elevation: 0, // Remove shadow on Android
          shadowOpacity: 0, // Remove shadow on iOS
          borderBottomWidth: 0, // Remove bottom border
        },
        headerLeft: () => {
          // You might need to implement your custom logic here
          const goBack = navigation.canGoBack();

          return (
            <Pressable
              onPress={() => {
                if (goBack) {
                  navigation.goBack();
                } else {
                  navigation.replace("Dashboard");
                }
              }}
            >
              <AntDesign
                name="arrowleft"
                size={24}
                color="black"
                style={{ marginLeft: 20 }}
              />
            </Pressable>
          );
        },
      })}
      // screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen name="Profile" component={ProfileScreen} />
      <Stack.Screen name="OrderHistory" component={OrderHistoryScreen} />
      <Stack.Screen name="EditProfile" component={EditProfileScreen} />
      <Stack.Screen name="ProfileCards" component={ProfileCardsPage} />
      <Stack.Screen name="Fallback" component={PaymentFallback} />
      <Stack.Screen name="Product" component={ProductScreen} />
      <Stack.Screen name="ProfileCategory" component={ProfileCategoryScreen} />
      <Stack.Screen name="Pages" component={PagesScreen} />
      <Stack.Screen name="Create" component={FormScreen} />
      <Stack.Screen name="ContactUs" component={ContactUsScreen} />
      <Stack.Screen name="Review" component={ReviewScreen} />
      <Stack.Screen name="Checkout" component={CheckoutScreen} />
      <Stack.Screen name="OrderComplete" component={OrderCompleteScreen} />
      <Stack.Screen name="SubmitReview" component={SubmitReviewScreen} />

      <Stack.Screen
        name="ChooseSubInput"
        component={ChooseSubInputScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ChooseFamilyType"
        component={ChooseFamilyType}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ChooseFile"
        component={ChooseFileScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ChooseSubSelection"
        component={ChooseSubSelectionScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="ProfileSubCategory"
        component={ProfileSubCategoryScreen}
      />
    </Stack.Navigator>
  );
};

export default LoggedInNavigator;
