import { Pressable, StyleSheet, Text, View } from "react-native";
import * as DocumentPicker from "expo-document-picker";

import React, { useEffect, useState } from "react";
import { AntDesign, Foundation } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { uploadPhoto } from "../store/actions/cms";
import CustomText from "./CustomText";
const { v4: uuidv4 } = require("uuid");

const AudioPicker = ({ onModalClose }) => {
  const token = useSelector((state) => state?.user?.token);
  const dispatch = useDispatch();

  //   const pickImage = async () => {
  //     // No permissions request is necessary for launching the image library
  //     let result = await ImageModule.launchImageLibraryAsync({
  //       allowsEditing: true,
  //       aspect: [1, 1],
  //       quality: 0.6,
  //     });

  //   if (!result.canceled) {
  //     const response = await fetch(result.assets[0].uri);
  //     const imgData = await response.blob();
  //     // console.log(imgData, imgData.type, typeof imgData, result.assets[0]);
  //     const data = await dispatch(uploadPhoto(token, createFormData(imgData)));
  //     onModalClose(data);
  //   }
  //   };
  const pickDocument = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: "audio/*",
    });
    if (!result.canceled) {
      const response = await fetch(result.assets[0].uri);
      console.log(result.assets[0]);
      const imgData = await response.blob();
      console.log(imgData, imgData.type, typeof imgData, result.assets[0]);
      const data = await dispatch(
        uploadPhoto(token, createFormData(imgData, result.assets[0].name))
      );
      onModalClose(data);
    }
  };

  const createFormData = (blob, name) => {
    const formData = new FormData();
    if (blob) {
      formData.append("file", blob, name);
      // formData.append("file", blob);
    }

    return formData;
  };

  return (
    <Pressable onPress={pickDocument} style={styles.container}>
      <Foundation name="music" size={24} />
      <CustomText style={styles.heading}>Click here to upload</CustomText>
    </Pressable>
  );
};

export default AudioPicker;

const styles = StyleSheet.create({
  container: {
    height: 60,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    columnGap: 16,
  },
  heading: {
    fontSize: 18,
  },
});
